<template>
  <v-card>
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex justify-center">
          <v-img
            src="@/assets/img/setupwelcome.png"
            contain
            :style="'max-height: 400px !important;'"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero monteserrat500"
        >
          <h4>{{ user.firstName }}</h4>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero monteserrat500"
        >
          <h4>
            Bardzo się cieszę, że tutaj jesteś
            <v-icon :style="'color: #EC69FF; font-size: 35px !important;'"
              >fas fa-heart</v-icon
            >
          </h4>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero monteserrat500"
        >
          <h4 v-if="isNewUser">Rozpoczynamy wspólną przygodę! Trenuj, jedz zdrowo i ViveFit!</h4>
          <h4 v-else>Twój plan premium dobiegł końca! Odnów już dziś i kontynuuj przygodę z ViveFit!</h4>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" v-if="isNewUser">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero monteserrat500"
        >
          <h4>
            <v-icon :style="'color: #EC69FF; font-size: 30px !important;'"
              >fas fa-weight</v-icon
            >
            Twoje BMI to {{ user.userConfig.bmi }}
          </h4>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" v-if="isNewUser">
        <v-col cols="12" class="text-center monteserrat500">
          <span>
            • Poniżej 18.5 Niedowaga<br />
            • 18.5-24.9 Prawidłowa masa ciała<br />
            • 25.0-29.9 Nadwaga<br />
            • 30.0-34.9 Otyłość stopnia I <br />
            • 35.0-39.9 Otyłość stopnia II<br />
            • Powyżej 40 Otyłość stopnia III<br />
          </span>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" v-if="isNewUser">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero monteserrat500"
        >
          <h4>
            <v-icon :style="'color: #EC69FF; font-size: 35px !important;'"
              >fas fa-utensils</v-icon
            >
            Aby uzyskać swój cel
            {{
              user.userConfig.gender == "female" ? "powinnaś" : "powinneś"
            }}
            spożywać {{ user.userConfig.kcal }}kcal
          </h4>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" v-if="isNewUser">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero-medium monteserrat500"
        >
          <h4>Twoje CPM to {{ user.userConfig.cpm_kcal }}kcal</h4>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" v-if="isNewUser">
        <v-col cols="12" class="text-center monteserrat500">
          <span>
            Twoje zapotrzebowanie kaloryczne uwzględnia regularne treningi z
            aplikacją. Pamiętaj, że to orientacyjne wyliczenia, które mogą
            różnić się u poszczególnych osób ze względu na indywidualne cechy i
            parametry dodatkowe. Jeśli masz wątpliwości skonsultuj to z lekarzem
            lub dietetykiem.
          </span>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center" v-if="isNewUser">
        <v-col
          cols="12"
          class="text-typo text-center font-size-hero monteserrat500"
        >
          <h4>
            <v-icon :style="'color: #535dff; font-size: 35px !important;'"
              >fas fa-tint</v-icon
            >
            Dbaj o prawidłowe nawodnienie.<br />
            Przy Twojej wadze
            {{
              user.userConfig.gender == "female" ? "powinnaś" : "powinneś"
            }}
            wypijać {{ user.userConfig.water }} ml, czyli ok.
            {{ parseInt(user.userConfig.water / 300) }} szklanek płynów
          </h4>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" class="pb-0 d-flex justify-center">
          <v-btn
            color="#fff"
            class="font-weight-bolder py-4 px-7 mb-3"
            :class="'btn-primary button-primary-gradient'"
            rounded
            large
            @click="goToPayment()"
          >
            {{isNewUser?'Zaczynamy':'Odnów premium'}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "PaymentTeaser",
  computed: {
    user() {
      return this.$store.state.user;
    },
    isNewUser(){
      return ((Date.now()-Date.parse(this.$store.state.user.createdAt))/1000)<2592000;
    },
    bmi(){
      let meters = (this.height/100);
      return Math.round(this.weight/(meters*meters) * 10) / 10;
    },
    
  },
  methods: {
    goToPayment() {
      this.$router.push("/paymentplans");
    },
  },
};
</script>

<style>
</style>