/* eslint-disable prettier/prettier */
import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
const { axios } = require('../plugins/axios');

Vue.use(Vuex)


let PROMOTION_CONFIG = {
  "start":1651906800,
  "end":1652306399
};



function isPromotion(start,end)
{
  let TS_NOW = parseInt(new Date().getTime()/1000);
  if ((start<TS_NOW) && (end>TS_NOW))
  {
    return true;
  }
  return false;
}



const PLANS = [
  {
    title: "30 dni",
    price: "69",
    discount:0,
    planId:"30days_premium",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 30 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "90 dni",
    price: "159",
    discount:0,
    planId:"90days_premium",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 90 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "1 rok",
    price: "399",
    discount:0,
    planId:"365days_premium",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 365 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
];

const OTHER_PROMO_PLANS = [
  {
    title: "30 dni - MAJ",
    price: "69",
    discount: 50,
    planId:"30days_premium_other_promo",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 30 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "90 dni - MAJ",
    price: "159",
    discount: 50,
    planId:"90days_premium_other_promo",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 90 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "1 rok - MAJ",
    price: "399",
    discount: 50,
    planId:"365days_premium_other_promo",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 365 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
]

const BIRTHDAY_PLANS = [
  {
    title: "30 dni - Promocja Urodzinowa",
    price: "69",
    discount: 50,
    planId:"30days_premium_bd",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 30 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "90 dni - Promocja Urodzinowa",
    price: "159",
    discount: 50,
    planId:"90days_premium_bd",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 90 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "1 rok - Promocja Urodzinowa",
    price: "399",
    discount: 50,
    planId:"365days_premium_bd",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 365 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
];


const BLACK_FRIDAY_PLANS = [
  {
    title: "30 dni - Black Friday",
    price: "69",
    discount: 55,
    planId:"30days_premium_bf",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 30 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "90 dni - Black Friday",
    price: "159",
    discount: 55,
    planId:"90days_premium_bf",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 90 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "1 rok - Black Friday",
    price: "399",
    discount: 55,
    planId:"365days_premium_bf",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 365 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
];

const DISCOUNT_PLANS = [
  {
    title: "30 dni",
    price: "69",
    discount:0,
    planId:"30days_premium",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 30 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "90 dni",
    price: "159",
    discount:20,
    planId:"90days_premium_discount",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 90 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "1 rok",
    price: "399",
    discount:20,
    planId:"365days_premium_discount",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 365 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
];

const PREORDER_PLANS = [
  {
    title: "30 dni",
    price: "69",
    discount:10,
    planId:"30days_premium_preorder",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 30 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "90 dni",
    price: "159",
    discount:10,
    planId:"90days_premium_preorder",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 90 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
  {
    title: "1 rok",
    price: "399",
    discount:10,
    planId:"365days_premium_preorder",
    buttonColor: "primary",
    buttonText: "Zaczynamy",
    features: [
      {
        title: "Dostęp PREMIUM przez 365 dni",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "100+ różnorodnych treningów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "250+ smacznych fit przepisów",
        icon: "fas fa-check",
        color: "primary",
      },
      {
        title: "23+ motywujących wyzwań",
        icon: "fas fa-check",
        color: "primary",
      },
      // {
      //   title: "co tydzień nowy trening i przepis",
      //   icon: "fas fa-check",
      //   color: "primary",
      // },
    ],
  },
];

//let PRODUCTS = require('../../config-files/products-preorder.json');


const getDate = function (date = false) {
  if (!date) {
      date = new Date()
  }
  return (
      date.getFullYear() +
      "-" +
      ((date.getMonth() + 1).toString().length == 1
          ? `0${(date.getMonth() + 1).toString()}`
          : `${(date.getMonth() + 1).toString()}`) +
      "-" +
      (date.getDate().toString().length == 1
          ? `0${date.getDate().toString()}`
          : `${date.getDate().toString()}`)
  );
};

export default new Vuex.Store({
  state: {
    isPreorder:false,
    isBlackFriday: true,
    isBirthday: true,
    isOtherPromo: true,
    isAuthenticated: false,
    isAdmin: false,
    userHasConfig: false,
    userHasActivePlan: false,
    userHasActiveChallengeSession: false,
    emailVerified: false,
    contentConfig: null,
    user: null,
    userConfig:false,
    userActivePlan:false,
    userActiveChallengeSession:false,
    drawerActive: null,
    contentLockedDialog:false,
    meDialog:false,
    paymentPlans: PLANS,
    preorderPlans: PREORDER_PLANS,
    blackFridayPlans: BLACK_FRIDAY_PLANS,
    birthdayPlans: BIRTHDAY_PLANS,
    otherPromoPlans: OTHER_PROMO_PLANS,
  },
  getters: {
    isPreorder: (state) =>{
      return state.isPreorder;
    },
    isBlackFriday: (state) =>{
      return state.isBlackFriday && moment.utc().isBetween(
        moment.utc('2023-11-17T00:01+01'),
        moment.utc('2023-11-27T23:59+01')
      );
    },
    isBirthday: (state) => {
      return state.isBirthday && moment.utc().isBetween(
        moment.utc('2023-07-24T00:01+01'),
        moment.utc('2023-08-03T23:59+01')
      )
    },
    isOtherPromo: (state) => {
      return state.isOtherPromo && moment.utc().isBetween(
        moment.utc('2024-05-10T00:01+02'),
        moment.utc('2024-05-19T23:59+02')
      )
    },
    meDialog: (state) =>{
      return state.meDialog;
    },
    contentLockedDialog: (state) =>{
      return state.contentLockedDialog;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    isAdmin: (state) => {
      return state.isAdmin;
    },
    userHasConfig: (state) => {
      return state.userHasConfig;
    },
    userHasActivePlan: (state) => {
      return state.userHasActivePlan;
    },
    userActivePlan: (state) => {
      return state.userActivePlan;
    },
    userHasActiveChallengeSession: (state) => {
      return state.userHasActiveChallengeSession;
    },
    userActiveChallengeSession: (state) => {
      return state.userActiveChallengeSession;
    },
    isEmailVerified: (state) => {
      return state.emailVerified;
    },
    hasUser: (state) => {
      return state.user !== null;
    },
    getUser: (state) => {
      return state.user;
    },
    getContentCOnfig: (state)=>{
      return state.contentConfig;
    },
    getPaymentPlans:(state)=>{
      state;
      return (isPromotion(PROMOTION_CONFIG.start,PROMOTION_CONFIG.end))?DISCOUNT_PLANS:PLANS;
    }
  },
  mutations: {
    setAuth(state, { isAuthenticated, user }) {
      state.isAuthenticated = isAuthenticated;
      state.isAdmin = user.isAdmin;
      state.user = user;
      state.userConfig = user.userConfig;
      if (state.userConfig)
      {
        state.userHasConfig = true;
      }
      else
      {
        state.userHasConfig = false;
      }
      state.userActivePlan = user.userActivePlan;
      if (state.userActivePlan && state.userActivePlan.active)
      {
        state.userHasActivePlan = true;
      }
      else
      {
        state.userHasActivePlan = false;
      }
      state.userActiveChallengeSession = user.activeChallengeSession;
      if (state.userActiveChallengeSession && !state.userActiveChallengeSession.every(i => i.finished))
      {
        state.userHasActiveChallengeSession = true;
      }
      else
      {
        state.userHasActiveChallengeSession = false;
      }
      state.emailVerified = user.emailVerified;
    },
    setConfig(state, {user}) {
      state.userConfig = user.userConfig;
      if (state.userConfig)
      {
        state.userHasConfig = true;
      }
      else
      {
        state.userHasConfig = false;
      }
      state.userActivePlan = user.userActivePlan;
      if (state.userActivePlan && state.userActivePlan.active)
      {
        state.userHasActivePlan = true;
      }
      else
      {
        state.userHasActivePlan = false;
      }
      state.emailVerified = user.emailVerified;
      state.user.emailVerified = user.emailVerified;

    },
    setContentConfig(state,{config})
    {
      state.contentConfig = config;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
    setDrawer(state, { value }) {
      state.drawerActive = value;
    },
    setMeDialog(state, value ) {
      state.meDialog = value;
    },
    setContentLockedDialog(state, value ) {
      state.contentLockedDialog = value;
    },
  },
  actions: {
    fetchUser({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          axios.post("/api/auth/user",{
            day:getDate()
          }).then(response => {
            if (response.data.user !== false) {
              commit('setAuth', {
                isAuthenticated: true, user: response.data.user
              })
              commit('setContentConfig', {
                config:response.data.content_config
              });
              // eslint-disable-next-line no-unused-vars
              resolve(true);
            }
            else {
              commit('logout')
              // eslint-disable-next-line no-unused-vars
              reject(false);
            }
          }, error => {
            console.log(error);
            commit('logout')
            reject(false);
          });
        }
        catch (error) {
          console.log('error ', error);
          commit('logout')
          // eslint-disable-next-line no-unused-vars
          reject(false);
        }
      });

    },
    fetchUserConfig({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          axios.post("/api/auth/userconfig").then(response => {
            if (response.data.user !== false) {
              commit('setConfig', {
                user: response.data.user
              })
              // eslint-disable-next-line no-unused-vars
              resolve(true);
            }
            else {
              commit('logout')
              // eslint-disable-next-line no-unused-vars
              reject(false);
            }
          }, error => {
            console.log(error);
            commit('logout')
            reject(false);
          });
        }
        catch (error) {
          console.log('error ', error);
          commit('logout')
          // eslint-disable-next-line no-unused-vars
          reject(false);
        }
      });

    }
  },
  modules: {
  }
})
