module.exports.install = function (Vue) {
    const getDate = function (date = false) {
        if (!date) {
            date = new Date()
        }
        return (
            date.getFullYear() +
            "-" +
            ((date.getMonth() + 1).toString().length == 1
                ? `0${(date.getMonth() + 1).toString()}`
                : `${(date.getMonth() + 1).toString()}`) +
            "-" +
            (date.getDate().toString().length == 1
                ? `0${date.getDate().toString()}`
                : `${date.getDate().toString()}`)
        );
    };
    Object.defineProperty(Vue.prototype, "$getDate", { value: getDate });
};