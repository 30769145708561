<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card class="mt-6 ml-2 mr-2 rounded-xl">
        <v-stepper v-model="stepper" elevation="1">
          <v-stepper-header>
            <v-stepper-step
              :complete="stepper > 1"
              @click="stepper > 1 && (stepper = 1)"
              step="1"
              :class="`${stepper > 1 ? 'cursor-pointer' : ''}`"
            >
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="stepper > 2"
              @click="stepper > 2 && (stepper = 2)"
              step="2"
              :class="`${stepper > 2 ? 'cursor-pointer' : ''}`"
            >
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              step="3"
              :complete="stepper > 3"
              @click="stepper > 3 && (stepper = 3)"
              :class="`${stepper > 3 ? 'cursor-pointer' : ''}`"
            >
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step
              step="4"
              :complete="stepper > 4"
              @click="stepper > 4 && (stepper = 4)"
              :class="`${stepper > 4 ? 'cursor-pointer' : ''}`"
            >
            </v-stepper-step>
            <v-divider v-if="firstLoad"></v-divider>

            <v-stepper-step
            v-if="firstLoad"
              step="5"
              :complete="stepper > 5"
              @click="stepper > 5 && (stepper = 5)"
              :class="`${stepper > 5 ? 'cursor-pointer' : ''}`"
            >
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-container fluid>
                  <v-row class="d-flex justify-center" v-if="!userHasConfig">
                    <v-col cols="12">
                      <h2 class="text-center text-typo montserrat500">
                        Witaj, {{ user.firstName }}
                        <v-icon
                          :style="'color: #EC69FF; font-size: 29px !important;'"
                          >fas fa-heart</v-icon
                        >
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Płeć
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="d-flex justify-end">
                      <v-btn
                        :class="`${
                          gender == 'female'
                            ? 'bg-gradient-primary'
                            : 'bg-gradient-secondary'
                        }`"
                        icon
                        large
                        @click="gender = 'female'"
                      >
                        <v-icon color="white">fas fa-venus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-start">
                      <v-btn
                        :class="`${
                          gender == 'male'
                            ? 'bg-gradient-primary'
                            : 'bg-gradient-secondary'
                        }`"
                        icon
                        large
                        @click="gender = 'male'"
                      >
                        <v-icon color="white">fas fa-mars</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex justify-center">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Twój wiek
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Wiek"
                        v-model="age"
                        type="number"
                        min="18"
                        max="100"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Twój wzrost (cm)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Wzrost (cm)"
                        v-model="height"
                        type="number"
                        min="100"
                        max="250"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Twoja waga (kg)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Waga (kg)"
                        v-model="weight"
                        type="number"
                        min="20"
                        max="300"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Obwód - Klatka piersiowa (cm)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Obwód (kg)"
                        v-model="circuits.chest"
                        type="number"
                        min="20"
                        max="300"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Obwód - Talia (cm)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Obwód (kg)"
                        v-model="circuits.waist"
                        type="number"
                        min="20"
                        max="300"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Obwód - Biodra (cm)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Obwód (kg)"
                        v-model="circuits.hips"
                        type="number"
                        min="20"
                        max="300"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Obwód - Ramię (cm)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Obwód (kg)"
                        v-model="circuits.shoulder"
                        type="number"
                        min="20"
                        max="300"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" class="pb-0">
                      <h4 class="text-center text-typo montserrat500">
                        Obwód - Udo (cm)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center" v-if="userHasConfig">
                    <v-col cols="12" sm="8" md="6">
                      <v-text-field
                        hide-details
                        outlined
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        height="40"
                        placeholder="Obwód (kg)"
                        v-model="circuits.thigh"
                        type="number"
                        min="20"
                        max="300"
                        class="
                          input-style
                          font-size-input
                          border border-radius-md
                          placeholder-lighter
                          text-color-light
                          mb-4
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="pb-0 d-flex justify-center">
                      <v-btn
                        color="#fff"
                        class="font-weight-bolder py-4 px-7 mb-3"
                        :class="'btn-primary button-primary-gradient'"
                        rounded
                        large
                        @click="validateStep(1)"
                      >
                        Dalej
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card>
                <v-container fluid>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12">
                      <h2 class="text-center text-typo montserrat500">
                        Jaki tryb życia prowadzisz? Poza treningami 😉
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center montserrat500">
                    <v-col
                      cols="12"
                      sm="10"
                      md="8"
                      class="d-flex justify-center"
                    >
                      <v-radio-group
                        v-model="lifestyleGroup"
                        :style="'wdith: 100% !important; flex: auto;'"
                      >
                        <v-card
                          v-for="style in lifestyles"
                          :key="style.value"
                          class="pa-2 mb-3 lifestyle-pick"
                          @click="lifestyleGroup = style.value"
                          :style="`background-color: ${
                            lifestyleGroup == style.value
                              ? '#9F38FF'
                              : 'rgba(57, 57, 57, 0.56)'
                          };`"
                        >
                          <v-container>
                            <v-row>
                              <v-col cols="1" class="d-flex align-center">
                                <v-radio
                                  :label="``"
                                  :value="style.value"
                                  color="white"
                                  large
                                >
                                </v-radio>
                              </v-col>
                              <v-col cols="11">
                                <h3 :style="'color: white;'">
                                  {{ style.label }}
                                </h3>
                                <span :style="'color: white;'">{{
                                  style.description
                                }}</span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex justify-center"
                    v-if="gender === 'female'"
                  >
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex justify-center montserrat500"
                    v-if="gender === 'female'"
                  >
                    <v-col
                      cols="12"
                      sm="10"
                      md="8"
                      class="d-flex justify-center"
                    >
                      <v-card
                        class="pa-1 mb-3 lifestyle-pick"
                        width="100%"
                        height="auto"
                        @click="breastFeedCheck = !breastFeedCheck"
                        :style="`background-color: ${
                          breastFeedCheck ? '#9F38FF' : 'rgba(57, 57, 57, 0.56)'
                        };`"
                      >
                        <v-container>
                          <v-row>
                            <v-col cols="1" class="">
                              <v-checkbox
                                readonly
                                :label="``"
                                v-model="breastFeedCheck"
                                color="white"
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" class="d-flex align-center">
                              <h3 :style="'color: white;'">Karmię piersią</h3>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="pb-0 d-flex justify-center">
                      <v-btn
                        color="#fff"
                        class="font-weight-bolder py-4 px-7 mb-3"
                        :class="'btn-primary button-primary-gradient'"
                        rounded
                        large
                        @click="validateStep(2)"
                      >
                        Dalej
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card>
                <v-container>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12">
                      <h2 class="text-center text-typo montserrat500">
                        Jaki jest Twój poziom zaawansowania?
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center montserrat500">
                    <v-col
                      cols="12"
                      sm="10"
                      md="8"
                      class="d-flex justify-center"
                    >
                      <v-radio-group
                        v-model="loaGroup"
                        :style="'wdith: 100% !important; flex: auto;'"
                      >
                        <v-card
                          v-for="loa in loas"
                          :key="loa.value"
                          class="pa-2 mb-3 lifestyle-pick"
                          @click="loaGroup = loa.value"
                          :style="`background-color: ${
                            loaGroup == loa.value
                              ? '#9F38FF'
                              : 'rgba(57, 57, 57, 0.56)'
                          };`"
                        >
                          <v-container>
                            <v-row>
                              <v-col cols="1" class="d-flex align-center">
                                <v-radio
                                  :label="``"
                                  :value="loa.value"
                                  color="white"
                                  large
                                >
                                </v-radio>
                              </v-col>
                              <v-col cols="11">
                                <h3 :style="'color: white;'">
                                  {{ loa.label }}
                                </h3>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="pb-0 d-flex justify-center">
                      <v-btn
                        color="#fff"
                        class="font-weight-bolder py-4 px-7 mb-3"
                        :class="'btn-primary button-primary-gradient'"
                        rounded
                        large
                        @click="validateStep(3)"
                      >
                        Dalej
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card>
                <v-container>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12">
                      <h2 class="text-center text-typo montserrat500">
                        Jaki jest Twój cel? Możesz wybrać kilka 🙂
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex justify-center montserrat500"
                    v-for="target in targets"
                    :key="target.value"
                  >
                    <v-col
                      cols="12"
                      sm="10"
                      md="8"
                      class="d-flex justify-center py-0"
                    >
                      <v-card
                        class="pa-1 mb-3 lifestyle-pick"
                        width="100%"
                        height="auto"
                        @click="
                          toggleTarget(target.value, target.bmi_protected)
                        "
                        :style="`background-color: ${
                          targetActive(target.value)
                            ? '#9F38FF'
                            : 'rgba(57, 57, 57, 0.56)'
                        };`"
                      >
                        <v-container>
                          <v-row>
                            <v-col cols="1" class="">
                              <v-checkbox
                                readonly
                                :label="``"
                                v-model="selectedTargets"
                                :value="target.value"
                                color="white"
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" class="d-flex align-center">
                              <h3 :style="'color: white;'">
                                {{ target.label }}
                              </h3>
                            </v-col>
                            <v-col
                              cols="11"
                              class="d-flex align-center justify-center"
                              v-if="
                                target.bmi_protected &&
                                !passBmiProtect(target.bmi_protected)
                              "
                            >
                              <span :style="'color: #ffefe4;'">
                                Twoje BMI jest zbyt niskie aby redukować tkankę
                                tłuszczową.
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex justify-center"
                    v-if="gender === 'female'"
                  >
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex justify-center montserrat500"
                    v-if="gender === 'female'"
                  >
                    <v-col
                      cols="12"
                      sm="10"
                      md="8"
                      class="d-flex justify-center py-0"
                    >
                      <v-card
                        class="pa-1 mb-3 lifestyle-pick"
                        width="100%"
                        height="auto"
                        @click="togglePregnantCheck()"
                        :style="`background-color: ${
                          pregnantCheck ? '#9F38FF' : 'rgba(57, 57, 57, 0.56)'
                        };`"
                      >
                        <v-container>
                          <v-row>
                            <v-col cols="1" class="">
                              <v-checkbox
                                readonly
                                :label="``"
                                v-model="pregnantCheck"
                                color="white"
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="11" class="d-flex align-center">
                              <h3 :style="'color: white;'">
                                Chcę być aktywna w ciąży
                              </h3>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" class="pb-0 d-flex justify-center">
                      <v-btn
                        color="#fff"
                        class="font-weight-bolder py-4 px-7 mb-3"
                        :class="'btn-primary button-primary-gradient'"
                        rounded
                        large
                        :loading="saveLoading"
                        @click="validateStep(4)"
                      >
                        Zapisz
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="5" v-if="firstLoad">
              <PaymentTeaser></PaymentTeaser>
            </v-stepper-content>
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="text-center">
                <p
                  v-if="stepError"
                  class="text-sm font-weight-bold text-red mb-1 text-center"
                >
                  {{ stepError }}
                </p>
              </v-col>
            </v-row>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import qs from "qs";
import PaymentTeaser from "@/components/util/PaymentTeaser.vue";
export default {
  name: "UserSetupStepper",
  data() {
    return {
      firstLoad:true,
      stepError: "",
      saveLoading: false,
      stepper: 1,
      gender: "female",
      age: "",
      height: "",
      weight: "",
      circuits:{
            chest:0,
            waist:0,
            hips:0,
            shoulder:0,
            thigh:0
        },
      lifestyleGroup: 1,
      lifestyles: [
        {
          value: 1,
          dietFactor: 1.4,
          label: "Siedzący tryb życia",
          description: `(mam mało ruchu w ciągu dnia i/lub wykonuję pracę w domu/biurze)`,
        },
        {
          value: 2,
          dietFactor: 1.5,
          label: "Mało aktywny",
          description: `(krótkie spacery i/lub lekka praca fizyczna)`,
        },
        {
          value: 3,
          dietFactor: 1.6,
          label: "Aktywny",
          description: `(długie spacery i/lub intensywna praca fizyczna)`,
        },
        {
          value: 4,
          dietFactor: 1.7,
          label: "Bardzo aktywny",
          description: `(dużo ruchu w ciągu dnia i/lub ciężka praca fizyczna)`,
        },
      ],
      breastFeedCheck: false,
      loaGroup: "1star",
      loas: [
        {
          value: "1star",
          label: "Dopiero zaczynam",
        },
        {
          value: "2star",
          label: "Ćwiczę od czasu do czasu",
        },
        {
          value: "3star",
          label: "Regularnie trenuję",
        },
        {
          value: "4star",
          label: "Wykonuję regularnie zaawansowane treningi",
        },
      ],
      selectedTargets: [],
      pregnantCheck: false,
      targets: [
        {
          value: "sprawn",
          kcalModifier: 0,
          label: "Chcę być bardziej sprawna/ny",
          bmi_protected: false,
        },
        {
          value: "schud",
          kcalModifier: -300,
          label: "Chcę zredukować tkankę tłuszczową",
          bmi_protected: true,
        },
        {
          value: "wyrze",
          kcalModifier: 0,
          label: "Chcę wyrzeźbić ciało",
          bmi_protected: false,
        },
        {
          value: "gibko",
          kcalModifier: 0,
          label: "Chcę zadbać o gibkość i mobilność ciała",
          bmi_protected: false,
        },
      ],
    };
  },
  mounted(){
      if (!this.userHasConfig)
      {
          return;
      }
      this.firstLoad = false;
      let config = this.userConfig;
      this.gender = config.gender;
      this.age = config.age;
      this.height = config.height;
      this.weight = config.weight;
      this.circuits = config.circuits;
      this.lifestyleGroup = config.lifestyle;
      this.breastFeedCheck = config.breastFeeding;
      this.loaGroup = config.loa;
      this.selectedTargets = config.targets.filter((target)=>{
        return target!=="fitmama";
      });
      this.pregnantCheck = config.isPregnant;
  },
  methods: {
    goToTop() {
      setTimeout(function () {
        document.getElementById("setup-top").scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    },

    toggleTarget(value, prot) {
      if (this.selectedTargets.includes(value)) {
        this.selectedTargets.splice(this.selectedTargets.indexOf(value), 1);
      } else {
        if (this.passBmiProtect(prot)) {
          this.pregnantCheck = false;
          this.selectedTargets.push(value);
        }
      }
    },
    targetActive(value) {
      return this.selectedTargets.includes(value);
    },
    clearTargets() {
      this.selectedTargets.splice(0, this.selectedTargets.length);
    },
    togglePregnantCheck() {
      if (!this.pregnantCheck) {
        this.clearTargets();
      }
      this.pregnantCheck = !this.pregnantCheck;
    },
    toNumber(str)
    {
      str = str.toString().replace(',','.').trim();
      return str * 1;
    },
    validateStep(number) {
      if (number == 1) {
        this.age = parseInt(this.age);
        this.height = parseInt(this.height);
        this.weight = this.toNumber(this.weight);
        if (isNaN(this.age) || this.age < 18) {
          this.setStepError("Podaj prawidłowy wiek");
        } else if (isNaN(this.height) || this.height < 100) {
          this.setStepError("Podaj prawidłowy wzrost");
        } else if (isNaN(this.weight) || this.weight < 20) {
          this.setStepError("Podaj prawidłową wagę");
        } else {
          this.toNextStep(number);
        }
      } else if (number == 2) {
        this.toNextStep(number);
      } else if (number == 3) {
        this.setLoading(false);
        this.toNextStep(number);
      } else if (number == 4) {
        if (this.selectedTargets.length == 0 && !this.pregnantCheck) {
          this.setStepError("Wybierz przynajmniej jeden cel");
        } else {
          this.saveConfig();
        }
      }
    },
    toNextStep(number) {
      this.setStepError();
      if (number <= 4) {
        this.stepper = number + 1;
        this.goToTop();
      }
    },
    setStepError(error = "") {
      this.stepError = error;
    },
    setLoading(val = false) {
      this.saveLoading = val;
    },
    async saveConfig() {
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: "/api/user/setconfig",
        data: qs.stringify({
          gender: this.gender,
          age: this.age,
          height: this.height,
          weight: this.weight,
          lifestyle: this.lifestyleGroup,
          breastFeeding: this.breastFeedCheck,
          loa: this.loaGroup,
          isPregnant: this.pregnantCheck,
          targets: this.selectedTargets,
          circuits:this.circuits,
          day:this.$getDate()
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(async (response) => {
          if (response.data.success) {
            await this.$store.dispatch("fetchUser");
            if (this.$store.getters["userHasConfig"]) {
                if (!this.firstLoad)
                {
                    this.$router.push('/dashboard');
                }
                else{
                    this.toNextStep(4);
                    this.setLoading(false);
                }
              
            }
          } else {
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    passBmiProtect(prot) {
      let meters = this.height / 100;
      let bmi = Math.round((this.weight / (meters * meters)) * 10) / 10;
      if (bmi < 18.5 && prot) {
        return false;
      }
      return true;
    },
  },
  components: {
    PaymentTeaser,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userHasConfig() {
      return this.$store.getters.userHasConfig || !this.firstLoad;
    },
    userConfig() {
      return this.$store.state.user.userConfig;
    },
    bmi() {
      let meters = this.height / 100;
      return Math.round((this.weight / (meters * meters)) * 10) / 10;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400&family=Montserrat:wght@500&display=swap");
.montserrat500 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

/* STEPPER STYLES */
.v-stepper__step--complete .v-stepper__step__step {
  padding: 15px !important;
}
.v-stepper__step__step {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background-color: #b362ff;
  color: white;
}

.v-stepper__content {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

/* LIFESTYLE */
.lifestyle-pick {
  transition: background-color 0.3s ease;
}
</style>