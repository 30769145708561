<template>
  <div>
    <v-container v-if="!saveLoading">
      <v-row class="justify-center d-flex mt-2">
        <v-col lg="4" md="7" class="mx-auto">
          <v-card class="card-shadow border-radius-xl">
            <div class="pt-5 text-center d-flex justify-center">
              <v-avatar size="100" class="cursor-pointer" @click="trigger = true">
                <v-img :src="user.avatarUrl" ></v-img>
              </v-avatar>
            </div>
            <div class="card-padding text-center d-flex justify-center">
              <h5 class="text-h5 text-typo font-weight-bold">
                Witaj, {{ user.firstName }}
              </h5>
            </div>
            <div class="text-center text-typo d-flex justify-center">
              <p class=" mb-0">
                Adres email:
              </p>
              
            </div>
            <div class="text-center d-flex justify-center mt-0 pt-0">
              
              <p>
                {{ user.email }}
              </p>
            </div>
            <div class="text-center d-flex justify-center">
              <v-btn
                elevation="0"
                :ripple="false"
                height="21"
                v-if="userHasActivePlan"
                :style="'color: white !important;'"
                class="
                  border-radius-xl
                  font-weight-bolder
                  px-3
                  py-3
                  badge-font-size
                  text-dark
                "
                color="#ff6440"
                >ViveFit Premium</v-btn
              >
            </div>
            <div class="text-center d-flex justify-center" v-if="userHasActivePlan">
              <p>
                Usługa premium do:
                {{
                  moment
                    .unix(user.userActivePlan.dueTimestamp)
                    .format("DD-MM-YYYY")
                }}
              </p>
            </div>
            <div class="card-padding text-center" v-if="userHasActivePlan">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                :to="'/paymentplans'"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-gem</v-icon
                >
                Przedłuż plan premium
              </v-btn>
            </div>
            <div class="card-padding text-center" v-if="!userHasActivePlan">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                :to="'/paymentplans'"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-gem</v-icon
                >
                Przejdź na premium
              </v-btn>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="trigger = true"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-user-circle</v-icon
                >
                Ustaw zdjęcie
              </v-btn>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="newPassDialog = true"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-lock</v-icon
                >
                Zmień hasło
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay id="saveOverlay" :opacity="1" :value="saveLoading" :style="'z-index: 999999;'">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
        <span :style="'color: white;'">Zapisuję...</span>
      </v-progress-circular>
    </v-overlay>
    <avatar-cropper
      v-model="trigger"
      upload-url="/api/user/setavatar"
      @uploading="handleUploading"
      @uploaded="handleUploaded"
      @completed="handleCompleted"
      @error="handleError"
      upload-file-name="avatar"
      :labels="{ submit: 'Zapisz', cancel: 'Anuluj' }"
    />
    <v-dialog
      v-model="newPassDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="newPassDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Ustaw nowe hasło</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container class="p-4">
          <p
            v-if="setPasswordError"
            class="text-sm font-weight-bold text-red mb-1 text-center"
          >
            {{ setPasswordError }}
          </p>
          <v-text-field
            hide-details
            outlined
            background-color="rgba(255,255,255,.9)"
            color="rgba(0,0,0,.6)"
            light
            height="40"
            v-model="password"
            :type="'password'"
            placeholder="Hasło"
            class="
              input-style
              font-size-input
              border border-radius-md
              placeholder-lighter
              text-color-light
              mb-4
            "
          >
          </v-text-field>
          <v-text-field
            hide-details
            outlined
            background-color="rgba(255,255,255,.9)"
            color="rgba(0,0,0,.6)"
            light
            height="40"
            :type="'password'"
            v-model="password_confirmation"
            placeholder="Potwierdź hasło"
            class="
              input-style
              font-size-input
              border border-radius-md
              placeholder-lighter
              text-color-light
              mb-4
            "
          >
          </v-text-field>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-1
              mb-2
              w-100
            "
            color="#5e72e4"
            @click="validateSetPassword"
            :loading="saveLoading"
            ><v-icon>fas fa-lock</v-icon>
            <span class="ml-1">Ustaw hasło</span>
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "ProfileView",
  data() {
    return {
      password: "",
      password_confirmation: "",
      trigger: false,
      saveLoading: false,
      newPassDialog: false,
      setPasswordError: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
  },
  methods: {
    handleUploading() {
      this.saveLoading = true;
    },

    handleUploaded() {
      this.trigger = false;
      this.saveLoading = false;
    },

    async handleCompleted() {
      await this.$store.dispatch("fetchUser");
      this.trigger = false;
      this.saveLoading = false;
    },

    handleError() {
      this.trigger = false;
      this.saveLoading = false;
    },
    setError(error) {
      this.setPasswordError = error;
    },
    setLoading(val) {
      this.saveLoading = val;
    },
    validateSetPassword() {
      if (
        this.password.trim().length == 0 ||
        this.password_confirmation.trim().length == 0
      ) {
        return this.setError("Podaj dane");
      } else if (this.password !== this.password_confirmation){
          return this.setError("Hasła się różnią");
      } else if (this.password.length<8){
          return this.setError("Hasło musi mieć min. 8 znaków");
      } else {
          this.setPassword();
      }
    },
    setPassword() {
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: "/api/user/newpassword",
        data: qs.stringify({
          password: this.password,
          password_confirmation: this.password_confirmation,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success) {
            this.setLoading(false);
            this.newPassDialog = false;
            this.setError('');
            
          } else {
            this.setError("Błędne dane!");
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading(false);
          this.setError("Błędne dane!");
        });
    }
  },
};
</script>

<style>
</style>