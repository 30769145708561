<template>
  <v-navigation-drawer
    fixed
    app
    floating
    :expand-on-hover="mini"
    v-bind:value="drawerActive"
    class="my-4 ms-4 border-radius-lg"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <v-img
              src="@/assets/img/logo_xwide.png"
              class="navbar-brand-img ms-1 rounded drawer-regular-image"
              width="200"
              v-if="true"
            >
            </v-img>
            <v-img
              src="@/assets/img/logo.png"
              class="navbar-brand-img ms-1 rounded drawer-regular-image"
              width="200"
              v-else
            >
            </v-img>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr class="horizontal dark mb-4" />

    <v-list nav dense>
      <v-list-item-group>
        <div v-for="(item, i) in itemsDocs" :key="i">
          <v-list-item
            v-if="item.withoutPremium || userHasActivePlan"
            link
            :to="item.link"
            class="pb-1 mx-2 no-default-hover"
            :ripple="false"
            active-class="item-active v-list-item--active v-list-item--link"
          >
            <v-list-item-icon class="shadow border-radius-md mx-2">
              <v-icon size="1rem" :style="`${item.style}`">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="ms-1 font-weight-bold"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div
        >
<!--          v-if="!userHasActiveChallengeSession"-->
          <v-list-item
            link
            :to="'/challenges'"
            class="pb-1 mx-2 no-default-hover"
            :ripple="false"
            active-class="item-active v-list-item--active v-list-item--link"
          >
            <v-list-item-icon class="shadow border-radius-md mx-2">
              <v-icon size="1rem" :style="`transform: translateX(-3px);`">
                fas fa-trophy
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Wyzwania'"
                class="ms-1 font-weight-bold"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
<!--        <div>-->
<!--&lt;!&ndash;          v-else&ndash;&gt;-->
<!--          <v-list-item-->
<!--            link-->
<!--            :to="'/mychallenge'"-->
<!--            class="pb-1 mx-2 no-default-hover"-->
<!--            :ripple="false"-->
<!--            active-class="item-active v-list-item&#45;&#45;active v-list-item&#45;&#45;link"-->
<!--          >-->
<!--            <v-list-item-icon class="shadow border-radius-md mx-2">-->
<!--              <v-icon size="1rem" :style="`transform: translateX(-3px);`">-->
<!--                fas fa-trophy-->
<!--              </v-icon>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title-->
<!--                v-text="'Moje Wyzwanie'"-->
<!--                class="ms-1 font-weight-bold"-->
<!--              ></v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
<!--        </div>-->
        <div v-for="item in itemsDocsBottom" :key="item.icon">
          <v-list-item
            v-if="item.withoutPremium || userHasActivePlan"
            link
            :to="item.link"
            class="pb-1 mx-2 no-default-hover"
            :ripple="false"
            active-class="item-active v-list-item--active v-list-item--link"
          >
            <v-list-item-icon class="shadow border-radius-md mx-2">
              <v-icon size="1rem" :style="`${item.style}`">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="ms-1 font-weight-bold"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-if="!userHasActivePlan">
          <v-list-item
            link
            :to="'/paymentplans'"
            class="pb-1 mx-2 no-default-hover"
            :ripple="false"
            active-class="item-active v-list-item--active v-list-item--link"
          >
            <v-list-item-icon class="shadow border-radius-md mx-2">
              <v-icon size="1rem" :style="`transform: translateX(-3px);`">
                fas fa-gem
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Dostęp Premium'"
                class="ms-1 font-weight-bold"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },
  computed: {
    drawerActive() {
      return this.$store.state.drawerActive;
    },
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
    userHasActiveChallengeSession() {
      return this.$store.state.userHasActiveChallengeSession;
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    itemsDocs: [
      {
        icon: "fas fa-calendar-check",
        link: "/dashboard",
        title: "Dziennik",
        external: false,
        withoutPremium: true,
        style: "transform: translateX(-1px);",
      },
      {
        icon: "fas fa-running",
        link: "/trainings",
        title: "Treningi",
        external: false,
        withoutPremium: true,
        style: "",
      },
      {
        icon: "fas fa-utensils",
        link: "/recipes",
        title: "Przepisy ",
        external: false,
        withoutPremium: true,
        style: "",
      },
      {
        icon: "fas fa-calendar-check",
        link: "/recentlyadded",
        title: "Ostatnio dodane",
        external: false,
        withoutPremium: true,
        style: "transform: translateX(-1px);",
      },
    ],
    itemsDocsBottom: [
      {
        icon: "fas fa-address-card",
        link: "/appkontakt",
        title: "Kontakt ",
        external: true,
        withoutPremium: true,
        style: "transform: translateX(-3px);",
      },
    ],
  }),
  methods: {},
  watch: {
    drawerActive() {},
  },
};
</script>
<style scoped>
.item-active i {
  color: #f9f9f9 !important;
}
i {
  color: gray !important;
}

.v-navigation-drawer__content
  .v-list-item.v-list-item--active
  .v-list-item__icon.shadow {
  background-image: linear-gradient(310deg, #ff6300, #ff6300) !important;
}
</style>
