<template>
  <span>
    <template v-if="$route.meta.layout=='blank' || $route.meta.hasFooter">
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" lg="8" class="mb-2 mx-auto text-center">
                <a
                  v-for="item in footer"
                  :key="item.linkName"
                  :href="item.link"
                  class="text-decoration-none text-secondary ls-0 mx-4 text-md"
                  target="_blank"
                  >{{ item.linkName }}</a
                >
              </v-col>
              
              <v-col cols="8" class="mx-auto text-center">
                <v-btn
                  v-for="item in icons"
                  :key="item.icon"
                  icon
                  width="31"
                  :ripple="false"
                  class="mx-3 text-secondary no-default-hover"
                  link
                  :href="item.link"
                  target="_blank"
                >
                  <v-icon size="18">{{ item.icon }}</v-icon>
                </v-btn>
              </v-col>
              
            </v-row>
            <v-row>
              <v-col cols="8" class="mx-auto text-center">
                <p class="copyright text-secondary text-md ls-0">
                  Copyright © {{ new Date().getFullYear() }}
                  <a
                    
                    class="
                      text-decoration-none
                      btn-hover
                      text-primary text-body-2
                      ls-0
                    "
                    >VIVEFIT</a
                  >
                </p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center pt-0" v-if="$route.meta.requiresAuth && $route.meta.layout!='default'">
                <ul
                  class="d-flex justify-center list-style-none pa-2"
                >
                  <li>
                    <a
                      @click="logout"
                      class="
                        text-decoration-none text-muted text-body-2
                        ls-0
                        p-0
                        btn-dark-hover
                        no-default-hover
                      "
                      >Wyloguj</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Regulamin",
          link: "/regulamin",
        },
        {
          linkName: "Kontakt",
          link: "/kontakt",
        },
        {
          linkName: "Blog",
          link: "https://treningfitness.com/",
        },
        
      ],
      icons: [
        {
          icon: "fab fa-facebook",
          link: "https://www.facebook.com/treningfitnesscom",
        },
        {
          icon: "fab fa-youtube",
          link: "https://www.youtube.com/c/TreningFitnessbyPaula",
        },
        {
          icon: "fab fa-instagram",
          link: "https://www.instagram.com/treningfitnesscom/",
        },
        
      ],
    };
  },
  methods:{
    async logout() {
      await this.$axios.get("/api/auth/logout");
      this.$store.commit("logout");
      this.$router.push("/");
    },
  }
};
</script>
