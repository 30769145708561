<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h3>Użytkownicy aktywni</h3>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="btn-primary button-primary-gradient"
            @click="newActive"
            :loading="newActiveLoading"
            >Nowy raport &nbsp; <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table
            dark
            class="rounded"
            fixed-header
            :style="'max-height: 500px;'"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Data</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Pobierz</th>
                  <th class="text-left">Usuń</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in activeReports" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ moment.unix(item.createdAt).format("DD-MM-YYYY HH:mm") }}
                  </td>
                  <td v-if="item.processed && !item.failed">Gotowy</td>
                  <td v-else-if="item.processed && item.failed">
                    Błąd przy tworzeniu raportu!
                  </td>
                  <td v-else>Trwa tworzenie...</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="!item.processed || item.failed"
                          :loading="currentDownloadId == item.id"
                          @click="downloadReport('active', item)"
                        >
                          <v-icon>fas fa-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Pobierz raport</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="!item.processed"
                          :loading="currentDeleteId == item.id"
                          @click="deleteReport('active', item.id)"
                        >
                          <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Usun raport</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-2" />
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h3>Użytkownicy nieaktywni</h3>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="btn-primary button-primary-gradient"
            @click="newInactive"
            :loading="newInactiveLoading"
            >Nowy raport &nbsp; <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table
            dark
            class="rounded"
            fixed-header
            :style="'max-height: 500px;'"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Data</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Pobierz</th>
                  <th class="text-left">Usuń</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in inactiveReports" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ moment.unix(item.createdAt).format("DD-MM-YYYY HH:mm") }}
                  </td>
                  <td v-if="item.processed && !item.failed">Gotowy</td>
                  <td v-else-if="item.processed && item.failed">
                    Błąd przy tworzeniu raportu!
                  </td>
                  <td v-else>Trwa tworzenie...</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="!item.processed || item.failed"
                          :loading="currentDownloadId == item.id"
                          @click="downloadReport('inactive', item)"
                        >
                          <v-icon>fas fa-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Pobierz raport</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="!item.processed"
                          :loading="currentDeleteId == item.id"
                          @click="deleteReport('inactive', item.id)"
                        >
                          <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Usun raport</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-2" />

      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h3>Użytkownicy nieaktywni za mniej niż 7 dni</h3>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="btn-primary button-primary-gradient"
            @click="newSoon"
            :loading="newSoonLoading"
            >Nowy raport &nbsp; <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-simple-table
            dark
            class="rounded"
            fixed-header
            :style="'max-height: 500px;'"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Data</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Pobierz</th>
                  <th class="text-left">Usuń</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in soonReports" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ moment.unix(item.createdAt).format("DD-MM-YYYY HH:mm") }}
                  </td>
                  <td v-if="item.processed && !item.failed">Gotowy</td>
                  <td v-else-if="item.processed && item.failed">
                    Błąd przy tworzeniu raportu!
                  </td>
                  <td v-else>Trwa tworzenie...</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="!item.processed || item.failed"
                          :loading="currentDownloadId == item.id"
                          @click="downloadReport('soon', item)"
                        >
                          <v-icon>fas fa-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Pobierz raport</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="!item.processed"
                          :loading="currentDeleteId == item.id"
                          @click="deleteReport('soon', item.id)"
                        >
                          <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Usun raport</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import qs from "qs";
const moment = require("moment");
export default {
  name: "ReportView",
  data() {
    return {
      inactiveReports: [],
      activeReports: [],
      soonReports: [],
      newInactiveLoading: false,
      newActiveLoading: false,
      newSoonLoading: false,
      currentDownloadId: false,
      currentDeleteId: false,
    };
  },
  components: {},
  created: function () {
    var self = this;
    this.fetchReports();
    setInterval(function () {
      self.fetchReports();
    }, 60000);
  },
  methods: {
    fetchReports: function () {
      this.$axios({
        method: "get",
        url: "/api/admin/get-reports",
      })
        .then(async (response) => {
          if (response.data.success) {
            this.inactiveReports = response.data.inactiveReports;
            this.activeReports = response.data.activeReports;
            this.soonReports = response.data.soonReports;
          } else {
            this.$toast.open({
              message: `<span class="font-weight-bold">Błąd podczas ładowania raportów</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(() => {
          this.$toast.open({
            message: `<span class="font-weight-bold">Błąd podczas ładowania raportów</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
        });
    },
    newInactive: function () {
      for (let i = 0; i < this.inactiveReports.length; i++) {
        if (!this.inactiveReports[i].processed) {
          this.$toast.open({
            message: `<span class="font-weight-bold">Już trwa tworzenie raportu!</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
          return;
        }
      }
      this.newInactiveLoading = true;
      this.$axios({
        method: "post",
        url: "/api/admin/new-inactive",
      })
        .then(async (response) => {
          this.newInactiveLoading = false;
          if (response.data.success) {
            this.fetchReports();
          } else {
            this.$toast.open({
              message: `<span class="font-weight-bold">Błąd podczas tworzenia raportu</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(() => {
          this.newInactiveLoading = false;
          this.$toast.open({
            message: `<span class="font-weight-bold">Błąd podczas tworzenia raportu</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
        });
    },
    newActive: function () {
      for (let i = 0; i < this.activeReports.length; i++) {
        if (!this.activeReports[i].processed) {
          this.$toast.open({
            message: `<span class="font-weight-bold">Już trwa tworzenie raportu!</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
          return;
        }
      }
      this.newActiveLoading = true;
      this.$axios({
        method: "post",
        url: "/api/admin/new-active",
      })
        .then(async (response) => {
          this.newActiveLoading = false;
          if (response.data.success) {
            this.fetchReports();
          } else {
            this.$toast.open({
              message: `<span class="font-weight-bold">Błąd podczas tworzenia raportu</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(() => {
          this.newActiveLoading = false;
          this.$toast.open({
            message: `<span class="font-weight-bold">Błąd podczas tworzenia raportu</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
        });
    },
    newSoon: function () {
      for (let i = 0; i < this.soonReports.length; i++) {
        if (!this.soonReports[i].processed) {
          this.$toast.open({
            message: `<span class="font-weight-bold">Już trwa tworzenie raportu!</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
          return;
        }
      }
      this.newSoonLoading = true;
      this.$axios({
        method: "post",
        url: "/api/admin/new-soon",
      })
        .then(async (response) => {
          this.newSoonLoading = false;
          if (response.data.success) {
            this.fetchReports();
          } else {
            this.$toast.open({
              message: `<span class="font-weight-bold">Błąd podczas tworzenia raportu</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(() => {
          this.newSoonLoading = false;
          this.$toast.open({
            message: `<span class="font-weight-bold">Błąd podczas tworzenia raportu</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
        });
    },
    downloadReport: function (type, report) {
      this.currentDownloadId = report.id;
      this.$axios({
        method: "post",
        url: `/api/admin/download-${type}`,
        data: qs.stringify({
          reportId: report.id,
        }),
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          this.currentDownloadId = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Raport-${
              type == "soon"
                ? "wTymTyg"
                : type == "active"
                ? "Aktywni"
                : "Nieaktywni"
            }-${moment.unix(report.createdAt).format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.currentDownloadId = false;
          console.log(err);
          this.$toast.open({
            message: `<span class="font-weight-bold">Błąd podczas pobierania raportu</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
        });
    },
    deleteReport: function (type, id) {
      this.currentDeleteId = id;
      this.$axios({
        method: "post",
        url: `/api/admin/delete-${type}`,
        data: qs.stringify({
          reportId: id,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          this.currentDeleteId = false;
          if (response.data.success) {
            this.fetchReports();
          } else {
            this.$toast.open({
              message: `<span class="font-weight-bold">Błąd podczas usuwania raportu</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch((err) => {
          this.currentDeleteId = false;
          console.log(err);
          this.$toast.open({
            message: `<span class="font-weight-bold">Błąd podczas usuwania raportu</span>`,
            type: "error",
            duration: 3000,
            position: "bottom",
          });
        });
    },
  },
};
</script>

<style>
</style>