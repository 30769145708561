<template>
  <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="dialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'">Przejdź na premium <v-icon class="mr-2 text-white">fas fa-gem</v-icon></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container class="p-1">
          <v-card class="p-1">
            <v-card class="card-shadow border-radius-xl">
          <div class="d-flex">
            <v-img
              src="@/assets/img/about_me.png"
              class="mt-2"
              width="300px"
              height="300px"
              :contain="true"
            ></v-img>
          </div>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
                Aby zobaczyć ten materiał przejdź na ViveFit Premium
            </h5>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-primary
                bg-gradient-primary
                py-2
                px-6
                me-2
                mt-3
                mb-2
                w-100
              "
              @click="goToPayment"
              color="#5e72e4"
              :loading="false"
              >
              <span class="ml-1">Zaczynamy <v-icon size="12">fas fa-arrow-right ms-2</v-icon></span>
            </v-btn>
          </div>
        </v-card>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name:'ContentLocked',
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        goToPayment: function(){
            this.$store.commit("setContentLockedDialog",false);
            this.$router.push('/paymentplans');
        }
    },
    watch:{
        dialog:{
            handler: function () {
                if (!this.dialog)
                {
                    this.$store.commit("setContentLockedDialog",false);
                }
            },
        },
        contentLockedDialog: {
            handler: function () {
                if (this.contentLockedDialog)
                {
                    this.dialog = this.contentLockedDialog;
                }
            },
        },
    },
    computed:{
        contentLockedDialog() {
            return this.$store.state.contentLockedDialog;
        },
    }
}
</script>

<style>

</style>