<template>
  <div>
    <v-container v-if="!syncLoading">
      <v-row class="justify-center d-flex mt-2">
        <v-col lg="4" md="7" class="mx-auto">
          <v-card class="card-shadow border-radius-xl">
            <div class="card-padding text-center d-flex justify-center">
              <v-avatar size="100">
                <v-img src="@/assets/img/admin.png"></v-img>
              </v-avatar>
            </div>
            <div class="card-padding text-center d-flex justify-center">
              <h5 class="text-h5 text-typo font-weight-bold">Admin Panel</h5>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="syncContent"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-sync</v-icon
                >
                Synchronizuj treści
              </v-btn>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="openVerify()"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-envelope</v-icon
                >
                Weryfikuj email
              </v-btn>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="openAssign()"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-gem</v-icon
                >
                Przyznaj dostęp
              </v-btn>
            </div>
            <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
            <div class="card-padding text-center">
              <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="openAssign(false)"
                :style="'text-transform: none;'"
                rounded
                large
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-user-times</v-icon
                >
                Odbierz dostęp
              </v-btn>
            </div>
            <v-divider
              class="mt-2 mb-2 ml-2 mr-2"
              v-if="status !== ''"
            ></v-divider>
            <div class="card-padding text-center" v-if="status === 'error'">
              <h5 class="text-h5 text-typo font-weight-bold">
                <v-icon
                  size="27px"
                  :style="'transform: translateY(-2px); color: red;'"
                  >fas fa-times-circle</v-icon
                >
                Wystąpił błąd synchronizacji
              </h5>
            </div>
            <div class="card-padding text-center" v-if="status === 'success'">
              <h5 class="text-h5 text-typo font-weight-bold">
                <v-icon
                  size="27px"
                  :style="'transform: translateY(-2px); color: green;'"
                  >fas fa-check-circle</v-icon
                >
                Synchronizacja udana!
              </h5>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="assignDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        width="400"
        :transition="
          this.$vuetify.breakpoint.mobile
            ? 'slide-x-transition'
            : 'dialog-bottom-transition'
        "
      >
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
        <v-card>
          <v-toolbar class="bg-gradient-default">
            <v-btn icon @click="assignDialog = false">
              <v-icon color="white">{{
                this.$vuetify.breakpoint.mobile
                  ? "fas fa-chevron-left"
                  : "fas fa-times"
              }}</v-icon>
            </v-btn>
            <v-toolbar-title :style="'color: white;'"
              >{{ assignMode ? "Przyznaj" : "Odbierz" }} dostęp</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
          </v-toolbar>
          <v-container class="p-4">
            <v-alert dense class="bg-gradient-info">
              <span :style="'color: white'">
                <v-icon :style="'color: white'">fas fa-info-circle</v-icon>
                Podaj adres email użykownika któremu chcesz
                {{ assignMode ? "przyznać" : "odebrać" }} dostęp premium
              </span>
            </v-alert>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              placeholder="Email"
              v-model="email"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-2
              "
            >
            </v-text-field>
            <v-select
              v-if="assignMode"
              :items="days"
              item-text="label"
              item-value="days"
              v-model="selectedDays"
              label="Okres"
              color="violet"
              class="ps-2"
            ></v-select>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-1
                mb-2
                w-100
              "
              color="#5e72e4"
              @click="assign()"
              :loading="assignBtnLoading"
              ><v-icon>fas fa-envelope</v-icon>
              <span class="ml-1">{{
                assignMode ? "Przyznaj" : "Odbierz"
              }}</span>
            </v-btn>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="verifyDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        width="400"
        :transition="
          this.$vuetify.breakpoint.mobile
            ? 'slide-x-transition'
            : 'dialog-bottom-transition'
        "
      >
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
        <v-card>
          <v-toolbar class="bg-gradient-default">
            <v-btn icon @click="verifyDialog = false">
              <v-icon color="white">{{
                this.$vuetify.breakpoint.mobile
                  ? "fas fa-chevron-left"
                  : "fas fa-times"
              }}</v-icon>
            </v-btn>
            <v-toolbar-title :style="'color: white;'"
              >Weryfikuj email</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
          </v-toolbar>
          <v-container class="p-4">
            <v-alert dense class="bg-gradient-info">
              <span :style="'color: white'">
                <v-icon :style="'color: white'">fas fa-info-circle</v-icon>
                Podaj adres email użykownika którego chcesz zweryfikować
              </span>
            </v-alert>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              placeholder="Email"
              v-model="verify_email"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-2
              "
            >
            </v-text-field>
            
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-1
                mb-2
                w-100
              "
              color="#5e72e4"
              @click="verify()"
              :loading="verifyBtnLoading"
              ><v-icon>fas fa-envelope</v-icon>
              <span class="ml-1">
                Weryfikuj</span>
            </v-btn>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
    <v-overlay id="syncOverlay" :opacity="1" :value="syncLoading">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
        <span :style="'color: white;'">Ładuję...</span>
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "AdminView",
  data() {
    return {
      syncLoading: false,
      assignBtnLoading: false,
      verifyBtnLoading: false,
      assignDialog: false,
      verifyDialog:false,
      status: "",
      email: "",
      verify_email: "",
      assignMode: false,
      days: [
        {
          label: "30 dni",
          days: 30,
        },
        {
          label: "60 dni",
          days: 60,
        },
        {
          label: "90 dni",
          days: 90,
        },
        {
          label: "180 dni",
          days: 180,
        },
        {
          label: "365 dni",
          days: 365,
        },
        {
          label: "5 lat",
          days: 1825,
        },
      ],
      selectedDays: 30,
    };
  },
  components: {},
  methods: {
    setLoading: function (val) {
      this.syncLoading = val;
    },
    setStatus: function (val) {
      this.status = val;
    },
    openAssign(assign = true) {
      this.assignMode = assign;
      this.assignDialog = true;
    },
    openVerify() {
      this.verifyDialog = true;
    },
    assign: function () {
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: `/api/admin/${this.assignMode?'assign':'take'}`,
        data: qs.stringify({
          email: this.email,
          days: this.selectedDays,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success) {
            this.setLoading(false);
            this.$toast.open({
              message: `<span class="font-weight-bold">${this.assignMode?'Przyznano':'Odebrano'} dostęp !</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
          } else {
            this.setLoading(false);
            this.$toast.open({
              message: `<span class="font-weight-bold">${response.data.error}</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    verify: function () {
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: `/api/admin/verifyemail`,
        data: qs.stringify({
          email: this.verify_email,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success) {
            this.setLoading(false);
            this.$toast.open({
              message: `<span class="font-weight-bold">Zweryfikowano adres !</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
          } else {
            this.setLoading(false);
            this.$toast.open({
              message: `<span class="font-weight-bold">${response.data.error}</span>`,
              type: "error",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    syncContent: function () {
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: "/api/admin/sync-content",
      })
        .then(async (response) => {
          if (response.data.success) {
            this.setLoading(false);
            this.setStatus("success");
          } else {
            this.setStatus("error");
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setStatus("error");
          this.setLoading(false);
        });
    },
  },
};
</script>

<style>
</style>