<template>
  <v-app-bar
    height="auto"
    class="py-1 px-2 pb-0 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="9" class="d-flex align-center pr-0">
          <v-img
            class="mx-2"
            src="@/assets/img/logo_wide.png"
            max-height="80"
            max-width="120"
            contain
          ></v-img>
          <template v-if="!$vuetify.breakpoint.smAndDown">
            <v-btn
              :ripple="false"
              elevation="0"
              class="font-weight-bolder font-large py-2 px-3 ms-2"
              @click="goToID('trainings')"
              rounded
              large
              :style="'text-transform: none; background-color: transparent!important; border-color: transparent!important; color: #bd06ff;'"
            >
              Treningi
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              class="font-weight-bolder font-large py-2 px-3 ms-2"
              @click="goToID('recipes')"
              rounded
              large
              :style="'text-transform: none; background-color: transparent!important; border-color: transparent!important; color: #bd06ff;'"
            >
              Przepisy
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              class="font-weight-bolder font-large py-2 px-3 ms-2"
              @click="goToID('pricing')"
              rounded
              large
              :style="'text-transform: none; background-color: transparent!important; border-color: transparent !important; color: #bd06ff;'"
            >
              Cennik
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              class="font-weight-bolder font-large py-2 px-3 ms-2"
              :to="'/kontakt'"
              rounded
              large
              :style="'text-transform: none; background-color: transparent!important; border-color: transparent !important; color: #bd06ff;'"
            >
              Kontakt
            </v-btn>
          </template>
        </v-col>

        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          cols="3"
          class="text-right d-flex align-center justify-end"
        >
          <template>
            <v-btn
              :ripple="false"
              elevation="1"
              color="#fff"
              class="font-weight-bolder font-large py-4 px-7 ms-2"
              :class="'btn-primary button-primary-gradient'"
              to="/signup"
              :style="'text-transform: none;'"
              rounded
              large
            >
              Utwórz konto
            </v-btn>
          </template>
          <template>
            <v-btn
              :ripple="true"
              elevation="0"
              class="font-weight-bolder font-large py-4 px-7 ms-2"
              :class="''"
              to="/login"
              rounded
              large
              :style="'text-transform: none; background-color: rgba(255, 212, 250, 0.48) !important; border-color: transparent !important; color: #bd06ff;'"
            >
              Zaloguj się
            </v-btn>
          </template>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-end pr-4" v-else>
          <div
            class="
              pa-2
              d-flex
              align-center
              justify-center
              flex-column
              cursor-pointer
            "
            @click="barDialog = true"
          >
            <div class="toggler-line-app-bar"></div>
            <div class="toggler-line-app-bar"></div>
            <div class="toggler-line-app-bar"></div>
          </div>
        </v-col>
      </v-row>
      <v-dialog
        v-model="barDialog"
        v-if="$vuetify.breakpoint.smAndDown"
        hide-overlay
        :fullscreen="true"
        :transition="
          !this.$vuetify.breakpoint.mobile
            ? 'slide-x-transition'
            : 'dialog-top-transition'
        "
      >
        <v-card>
          <v-toolbar flat class="py-3 px-2" height="auto">
            <v-img
              class="mx-2"
              src="@/assets/img/logo_wide.png"
              max-height="80"
              max-width="120"
              contain
            ></v-img>
            <v-spacer></v-spacer>
            <v-btn icon @click="barDialog = false">
              <v-icon color="#BE8DEB" class="font-xlarge">fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container class="px-5" :fluid="true">
            <v-row class="my-2">
              <v-col class="cursor-pointer">
                <v-btn
                  :ripple="false"
                  elevation="0"
                  class="font-weight-bolder font-largee py-2 px-3"
                  href="#recipes"
                  @click="barDialog = false"
                  block
                  rounded
                  large
                  :style="'text-transform: none; background-color: transparent!important; border-color: transparent!important; color: #bd06ff;'"
                >
                  Przepisy
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col class="cursor-pointer">
                <v-btn
                  :ripple="false"
                  elevation="0"
                  class="font-weight-bolder font-largee py-2 px-3"
                  href="#trainings"
                  @click="barDialog = false"
                  rounded
                  large
                  block
                  :style="'text-transform: none; background-color: transparent!important; border-color: transparent!important; color: #bd06ff;'"
                >
                  Treningi
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col class="cursor-pointer">
                <v-btn
                  :ripple="false"
                  elevation="0"
                  class="
                    font-weight-bolder
                    text-left
                    font-largee
                    py-2
                    px-3
                  "
                  href="#pricing"
                  @click="barDialog = false"
                  rounded
                  large
                  block
                  :style="'text-transform: none; background-color: transparent!important; border-color: transparent!important; color: #bd06ff;'"
                >
                  Cennik
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col class="cursor-pointer">
                <v-btn
                  :ripple="false"
                  elevation="0"
                  class="
                    font-weight-bolder
                    text-left
                    font-largee
                    py-2
                    px-3
                  "
                  href="#pricing"
                  :to="'/kontakt'"
                  rounded
                  large
                  block
                  :style="'text-transform: none; background-color: transparent!important; border-color: transparent!important; color: #bd06ff;'"
                >
                  Kontakt
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-4">
              <v-col class="d-flex justify-center">
                <template>
                  <v-btn
                    :ripple="true"
                    elevation="1"
                    color="#fff"
                    class="font-weight-bolder font-largee py-4 px-7"
                    :class="'btn-primary button-primary-gradient'"
                    to="/signup"
                    :style="'text-transform: none;'"
                    rounded
                    large
                  >
                    Utwórz konto
                  </v-btn>
                </template>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col class="d-flex justify-center">
                <template>
                  <v-btn
                    :ripple="true"
                    elevation="0"
                    class="font-weight-bolder font-largee py-4 px-7 ms-2"
                    :class="''"
                    to="/login"
                    rounded
                    large
                    :style="'text-transform: none; background-color: rgba(255, 212, 250, 0.48) !important; border-color: transparent!important; color: #bd06ff;'"
                  >
                    Zaloguj się
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  components: {},
  data() {
    return {
      barDialog: false,
    };
  },
  methods: {
    goToID: function (id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.font-large {
  font-size: 0.95rem !important;
}

.font-largee {
  font-size: 1.1rem !important;
}

.font-xlarge {
  font-size: 35px !important;
}
.toggler-line-app-bar {
  width: 30px;
  border-bottom: 2px rgb(206, 69, 206) solid;
  padding-bottom: 4px;
}
</style>
