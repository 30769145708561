<template>
  <v-container >
    <LogoHeader></LogoHeader>
    <v-row class="justify-center d-flex mt-6">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">Zarejestruj się</h5>
          </div>
          <div class="d-flex" v-if="false">
            <v-btn
              icon
              outlined
              rounded
              color="transparent"
              width="60px"
              height="60px"
              @click="loginFacebook"
              class="ms-auto me-3 btn-outline-light border-radius-md"
            >
              <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(3.000000, 3.000000)"
                    fill-rule="nonzero"
                  >
                    <circle
                      fill="#3C5A9A"
                      cx="29.5091719"
                      cy="29.4927506"
                      r="29.4882047"
                    ></circle>
                    <path
                      d="M39.0974944,9.05587273 L32.5651312,9.05587273 C28.6886088,9.05587273 24.3768224,10.6862851 24.3768224,16.3054653 C24.395747,18.2634019 24.3768224,20.1385313 24.3768224,22.2488655 L19.8922122,22.2488655 L19.8922122,29.3852113 L24.5156022,29.3852113 L24.5156022,49.9295284 L33.0113092,49.9295284 L33.0113092,29.2496356 L38.6187742,29.2496356 L39.1261316,22.2288395 L32.8649196,22.2288395 C32.8649196,22.2288395 32.8789377,19.1056932 32.8649196,18.1987181 C32.8649196,15.9781412 35.1755132,16.1053059 35.3144932,16.1053059 C36.4140178,16.1053059 38.5518876,16.1085101 39.1006986,16.1053059 L39.1006986,9.05587273 L39.0974944,9.05587273 L39.0974944,9.05587273 Z"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                </g>
              </svg>
            </v-btn>
            <v-btn
              icon
              outlined
              rounded
              color="transparent"
              width="60px"
              @click="loginGoogle"
              height="60px"
              class="me-auto btn-outline-light border-radius-md"
            >
              <svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1">
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(3.000000, 2.000000)"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                      fill="#34A853"
                    ></path>
                    <path
                      d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                      fill="#EB4335"
                    ></path>
                  </g>
                </g>
              </svg>
            </v-btn>
          </div>
          <div class="mt-2 text-center" v-if="false">
            <p
              class="
                text-sm
                font-weight-bold
                mb-2
                text-secondary text-border
                z-index-2
                bg-white
                px-3
              "
            >
              lub
            </p>
          </div>
          <div class="card-padding">
            <p
              v-if="signupError"
              class="text-sm font-weight-bold text-red mb-1 text-center"
            >
              {{ signupError }}
            </p>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              placeholder="Imię"
              v-model="firstname"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              placeholder="Email"
              v-model="email"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              v-model="password"
              :type="'password'"
              placeholder="Hasło"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :type="'password'"
              v-model="password_confirmation"
              placeholder="Potwierdź hasło"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <v-checkbox
              v-model="checkbox"
              color="#141727"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-typo text-body-2 ls-0"
                  >zgadzam się z
                  <a
                    @click.prevent="tosDialog = true"
                    class="text-dark font-weight-bolder text-decoration-none"
                    >Warunkami użytkowania</a
                  ></span
                >
              </template>
            </v-checkbox>
            <vue-recaptcha
              ref="captcha"
              :sitekey="$captchaSitekey"
              @verify="signup"
              :loadRecaptchaScript="true"
            >
              <button style="display: none"></button>
            </vue-recaptcha>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-primary
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
              "
              color="#5e72e4"
              small
              @click="validateSignup"
              :loading="signupLoading"
              >Dołącz</v-btn
            >

            <p class="text-sm text-body mt-3 mb-0">
              Masz już konto?
              <router-link
                :to="'login'"
                class="text-dark text-decoration-none font-weight-bolder"
                >Zaloguj
              </router-link>
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="tosDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        hide-overlay
        width="70vw"
        :transition="this.$vuetify.breakpoint.mobile?'slide-x-transition':'dialog-bottom-transition'"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
        <v-card>
          <v-toolbar class="bg-gradient-default">
            <v-btn icon @click="tosDialog = false">
              <v-icon color="white">{{this.$vuetify.breakpoint.mobile?'fas fa-chevron-left':'fas fa-times'}}</v-icon>
            </v-btn>
            <v-toolbar-title :style="'color: white;'"
              >Regulamin</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
          </v-toolbar>
          <v-card>
            <v-card-title>
              <span class="text-h5">Aplikacja VIVEFIT</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <TosText></TosText>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color=" darken-1" text @click="tosDialog = false">
                Zamknij
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="(tosDialog = false) && (checkbox = true)"
              >
                Zaakceptuj
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
    <Footer></Footer>
  </v-container>
</template>
<script>
import qs from "qs";
import VueRecaptcha from "vue-recaptcha";
import TosText from "@/components/util/TOS.vue"
import LogoHeader from "@/components/util/LogoHeader.vue"
import Footer from "@/components/Footer.vue";
export default {
  name: "SignUp",
  data() {
    return {
      updateFailed: false,
      checkbox: false,
      firstname: "",
      email: "",
      password: "",
      password_confirmation: "",
      signupError: "",
      signupLoading: false,
      tosDialog: false,
    };
  },
  components: {
    VueRecaptcha,
    TosText,
    LogoHeader,
    Footer
  },
  methods: {
    setError(error) {
      this.signupError = error;
    },
    setLoading(val) {
      this.signupLoading = val;
    },
    async fetchUser(showerr = true) {
      try {
        await this.$store.dispatch("fetchUser");
        if (this.$store.getters["hasUser"]) {
          if (!this.$store.getters["isEmailVerified"]) {
            this.$router.push("/verifyemail");
          } else {
            this.$router.push("/dashboard");
          }
        }
         else {
          if (showerr)
          {
            this.setError("Błędne dane rejestracji");
          }
        }
        this.setLoading(false);
      } catch (error) {
        if (showerr)
        {
          this.setError("Błędne dane rejestracji");
        }
        
        this.setLoading(false);
      }
    },
    validateSignup() {
      if (!this.checkbox) {
        return this.setError("Zaakceptuj warunki użytkowania");
      } else if (
        this.firstname.trim().length <2 ||
        this.email.trim().length == 0 ||
        this.password.trim().length == 0
      ) {
        return this.setError("Podaj dane do rejestracji");
      } else if (this.password !== this.password_confirmation){
          return this.setError("Hasła się różnią");
      } else if (this.password.length<8){
          return this.setError("Hasło musi mieć min. 8 znaków");
      } else {
        this.$refs.captcha.execute();
      }
    },
    signup(captcha_token) {
      this.$refs.captcha.reset();
      this.setLoading(true);
      this.$axios({
        method: "post",
        url: "/api/auth/local/register",
        data: qs.stringify({
          captcha: captcha_token,
          firstname: this.firstname.trim(),
          email: this.email.trim(),
          password: this.password.trim(),
          password_confirmation: this.password_confirmation.trim(),
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          if (response.data.success) {
            window.setTimeout(() => {
              this.fetchUser();
            }, 700);
          } else {
            this.setError(response.data.error);
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading(false);
          this.setError("Błędne dane rejestracji!");
        });
    },
    loginFacebook() {
      window.location.href = "/api/auth/facebook";
    },
    loginGoogle() {
      window.location.href = "/api/auth/google";
    },
  },
  async created() {
    if (!this.$store.getters["hasUser"]) {
       this.fetchUser(false);
    } else if (this.$store.getters["hasUser"]) {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style>

.captcha-mobile .grecaptcha-badge {
  visibility: hidden;
}
</style>
