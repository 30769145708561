import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

//VEWS

Vue.use( VueRouter );

import Landing from "../views/Landing.vue";

//import Dashboard from "../views/Dashboard.vue";
import ProfileView from "../views/Profile.vue";
import Login from "../views/auth/Login.vue";
import SignUp from "../views/auth/SignUp.vue";
import UserSetup from "../views/UserSetup.vue";
import PaymentPlansView from "../views/PaymentPlansView.vue";
import PaymentTeaserView from "../views/PaymentTeaserView.vue";

import AdminView from "../views/admin/Admin.vue";
import ReportsView from "../views/admin/ReportsView.vue";


const routes = [
  {
    path: "/admin",
    name: "Admin",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: true,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: AdminView
  },
  {
    path: "/reportsadmin",
    name: "Raporty",
    meta: {
      layout: "default",
      hasFooter:false,
      requiresAuth: true,
      requiresAdmin:true,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge:true
    },
    component: ReportsView
  },
  {
    path: "/",
    name: "Landing",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: false,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: Landing,
  },
  {
    path: "/dashboard",
    name: "Dziennik",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/Diary.vue" ),
  },
  {
    path: "/profile",

    name: "Profil",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: ProfileView,
  },
  {
    path: "/recipes",
    name: "Przepisy",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/Recipes.vue" ),
  },
  {
    path: "/trainings",
    name: "Treninigi",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/Trainings.vue" ),
  },
  {
    path: "/challenges",
    name: "Wyzwania",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: true,
      withActiveChallenge: true
    },
    component: () => import( "../views/Challenges.vue" ),
  },
  {
    path: "/mychallenge/:challengeid",
    name: "Wyzwanie",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: true,
      withActiveChallenge: true
    },
    component: () => import( "../views/MyChallenge.vue" ),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: false,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: Login,
  },
  {
    path: "/login/update",
    name: "ULogin",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: Login,
  },
  {
    path: "/signup",
    hasFooter: true,
    name: "Zarejestruj",
    meta: {
      layout: "blank",
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: false,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: SignUp,
  },
  {
    path: "/newpassword/:token",
    name: "Nowe hasło",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: false,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/auth/NewPassword.vue" ),
  },
  {
    path: "/verifyemail",
    name: "Zweryfikuj email",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/auth/VerifyEmail.vue" ),
  },
  {
    path: "/verifytoken/:token",
    name: "Zweryfikuj email",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/auth/VerifyEmailToken.vue" ),
  },
  {
    path: "/regulamin",
    name: "Regulamin",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/TOS.vue" ),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },

    component: () => import( "../views/Contact.vue" ),
  },
  {
    path: "/appkontakt",
    name: "Kontakt w aplikacji",
    meta: {
      layout: "default",
      hasFooter: true,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/Contact.vue" ),
  },
  {
    path: "/setup",
    name: "Ustaw Profil",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: UserSetup
  },
  {
    path: "/appsetup",
    name: "Ja",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: UserSetup
  },
  {
    path: "/welcome",
    name: "Przed płatnością",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: PaymentTeaserView
  },
  {
    path: "/paymentplans",
    name: "Wybierz plan",
    meta: {
      layout: "blank",
      hasFooter: true,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: PaymentPlansView
  },
  {
    path: "/recentlyadded",
    name: "Ostatnio dodane",
    meta: {
      layout: "default",
      hasFooter: false,
      requiresAuth: true,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: true,
      requiresConfig: true,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/RecentlyAdded.vue" ),
  },
  {
    path: "*",
    name: "Nie znaleziono",
    meta: {
      layout: "blank",
      hasFooter: false,
      requiresAuth: false,
      requiresAdmin: false,
      withAuth: true,
      requiresVerifiedEmail: false,
      requiresConfig: false,
      requiresPremium: false,
      withActiveChallenge: true
    },
    component: () => import( "../views/404.vue" ),
  },
];

const router = new VueRouter( {
  mode: "history",
  base: "/app",
  routes,
} );

router.beforeEach( ( to, from, next ) => {
  if ( to.path != '/login/update' )
  {
    localStorage.setItem( 'vive-next', to.path );
  }

  if ( to.matched.some( ( record ) => record.meta.requiresAuth ) && ( !store.getters.isAuthenticated || !store.getters.hasUser ) )
  {
    if ( from.path != '/' )
    {
      localStorage.setItem( 'vive-next', from.path );
    }
    next( "/login/update" );
    return;
  }
  if ( to.matched.some( ( record ) => record.meta.requiresAdmin ) && ( !store.getters.isAdmin ) )
  {
    next( "/dashboard" );
    return;
  }
  else if ( to.matched.some( ( record ) => !record.meta.withAuth ) && ( store.getters.isAuthenticated || store.getters.hasUser ) )
  {
    next( "/dashboard" );
    return;
  }
  else if ( to.matched.some( ( record ) => record.meta.requiresVerifiedEmail ) && !store.getters.isEmailVerified )
  {
    next( "/verifyemail" );
    return;
  }
  else if ( to.matched.some( ( record ) => record.meta.requiresConfig ) && !store.getters.userHasConfig )
  {
    next( "/setup" );
    return;
  }
  else if ( to.matched.some( ( record ) => record.meta.requiresPremium ) && !store.getters.userHasActivePlan )
  {
    next( "/welcome" );
    return;
  }
  else if ( to.matched.some( ( record ) => !record.meta.withActiveChallenge ) && store.getters.userHasActiveChallengeSession )
  {
    next( "/mychallenge" );
    return;
  }
  else
  {
    next();
    return;
  }
} );

export default router;
