var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Użytkownicy aktywni")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-primary button-primary-gradient",attrs:{"loading":_vm.newActiveLoading},on:{"click":_vm.newActive}},[_vm._v("Nowy raport   "),_c('v-icon',[_vm._v("fas fa-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"rounded",style:('max-height: 500px;'),attrs:{"dark":"","fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("#")]),_c('th',{staticClass:"text-left"},[_vm._v("Data")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Pobierz")]),_c('th',{staticClass:"text-left"},[_vm._v("Usuń")])])]),_c('tbody',_vm._l((_vm.activeReports),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(_vm.moment.unix(item.createdAt).format("DD-MM-YYYY HH:mm"))+" ")]),(item.processed && !item.failed)?_c('td',[_vm._v("Gotowy")]):(item.processed && item.failed)?_c('td',[_vm._v(" Błąd przy tworzeniu raportu! ")]):_c('td',[_vm._v("Trwa tworzenie...")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.processed || item.failed,"loading":_vm.currentDownloadId == item.id},on:{"click":function($event){return _vm.downloadReport('active', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Pobierz raport")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.processed,"loading":_vm.currentDeleteId == item.id},on:{"click":function($event){return _vm.deleteReport('active', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Usun raport")])])],1)])}),0)]},proxy:true}])})],1)],1),_c('hr',{staticClass:"mt-5 mb-2"}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Użytkownicy nieaktywni")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-primary button-primary-gradient",attrs:{"loading":_vm.newInactiveLoading},on:{"click":_vm.newInactive}},[_vm._v("Nowy raport   "),_c('v-icon',[_vm._v("fas fa-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"rounded",style:('max-height: 500px;'),attrs:{"dark":"","fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("#")]),_c('th',{staticClass:"text-left"},[_vm._v("Data")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Pobierz")]),_c('th',{staticClass:"text-left"},[_vm._v("Usuń")])])]),_c('tbody',_vm._l((_vm.inactiveReports),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(_vm.moment.unix(item.createdAt).format("DD-MM-YYYY HH:mm"))+" ")]),(item.processed && !item.failed)?_c('td',[_vm._v("Gotowy")]):(item.processed && item.failed)?_c('td',[_vm._v(" Błąd przy tworzeniu raportu! ")]):_c('td',[_vm._v("Trwa tworzenie...")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.processed || item.failed,"loading":_vm.currentDownloadId == item.id},on:{"click":function($event){return _vm.downloadReport('inactive', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Pobierz raport")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.processed,"loading":_vm.currentDeleteId == item.id},on:{"click":function($event){return _vm.deleteReport('inactive', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Usun raport")])])],1)])}),0)]},proxy:true}])})],1)],1),_c('hr',{staticClass:"mt-5 mb-2"}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Użytkownicy nieaktywni za mniej niż 7 dni")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-primary button-primary-gradient",attrs:{"loading":_vm.newSoonLoading},on:{"click":_vm.newSoon}},[_vm._v("Nowy raport   "),_c('v-icon',[_vm._v("fas fa-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"rounded",style:('max-height: 500px;'),attrs:{"dark":"","fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("#")]),_c('th',{staticClass:"text-left"},[_vm._v("Data")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Pobierz")]),_c('th',{staticClass:"text-left"},[_vm._v("Usuń")])])]),_c('tbody',_vm._l((_vm.soonReports),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(_vm.moment.unix(item.createdAt).format("DD-MM-YYYY HH:mm"))+" ")]),(item.processed && !item.failed)?_c('td',[_vm._v("Gotowy")]):(item.processed && item.failed)?_c('td',[_vm._v(" Błąd przy tworzeniu raportu! ")]):_c('td',[_vm._v("Trwa tworzenie...")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.processed || item.failed,"loading":_vm.currentDownloadId == item.id},on:{"click":function($event){return _vm.downloadReport('soon', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Pobierz raport")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.processed,"loading":_vm.currentDeleteId == item.id},on:{"click":function($event){return _vm.deleteReport('soon', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Usun raport")])])],1)])}),0)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }