<template>
  <div>
    <v-container :style="'max-width: 1000px'">
      <LogoHeader id="setup-top" v-bind:home="false"></LogoHeader>
    </v-container>
    <payment-plans></payment-plans>
    <!-- FOOTER -->
    <v-container class="">
      <Footer></Footer>
    </v-container>
  </div>
</template>

<script>
import LogoHeader from "@/components/util/LogoHeader.vue";
import Footer from "@/components/Footer.vue";
import PaymentPlans from "@/components/util/PaymentPlans.vue";
export default {
    name:'PaymentPlansView',
    components:{
      LogoHeader,
      Footer,
      PaymentPlans
    }
}
</script>

<style>

</style>