<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    hide-overlay
    width="600"
    :transition="
      this.$vuetify.breakpoint.mobile
        ? 'slide-x-transition'
        : 'dialog-bottom-transition'
    "
  >
    <v-card ref="dialogTop">
      <v-toolbar class="bg-gradient-primary">
        <v-btn icon @click="dialog = false">
          <v-icon color="white">{{
            this.$vuetify.breakpoint.mobile
              ? "fas fa-chevron-left"
              : "fas fa-times"
          }}</v-icon>
        </v-btn>
        <v-toolbar-title :style="'color: white;'"
          >Ja&nbsp;<v-icon class="mr-2 text-white"
            >fas fa-child</v-icon
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container class="p-1" ref="dialogTop">
        <v-card class="p-1 card-shadow border-radius-xl">
          <div class="d-flex justify-center">
            <div class="pt-5 text-center d-flex justify-center">
              <v-avatar size="100">
                <v-img :src="user.avatarUrl"></v-img>
              </v-avatar>
            </div>
          </div>
          <div class="d-flex justify-center mt-2">
            <h6 class="text-h6 text-typo font-weight-bold">
              {{ user.firstName }}
            </h6>
          </div>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="text-typo text-center font-size-hero monteserrat500"
            >
              <h6>
                <v-icon :style="'color: #EC69FF; font-size: 30px !important;'"
                  >fas fa-weight</v-icon
                >
                Twoje BMI to {{ user.userConfig.bmi }}
              </h6>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="text-typo text-center font-size-hero monteserrat500"
            >
              <h6>
                <v-icon :style="'color: #EC69FF; font-size: 35px !important;'"
                  >fas fa-utensils</v-icon
                >
                Aby uzyskać swój cel
                {{
                  user.userConfig.gender == "female" ? "powinnaś" : "powinneś"
                }}
                spożywać {{ user.userConfig.kcal }}kcal
              </h6>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center plp3 pr-3">
            <v-col
              cols="12"
              class="text-typo text-center font-size-hero monteserrat500"
            >
              <h6>
                <v-icon :style="'color: #535dff; font-size: 35px !important;'"
                  >fas fa-tint</v-icon
                >
                Dbaj o prawidłowe nawodnienie.<br />
                Przy Twojej wadze
                {{
                  user.userConfig.gender == "female" ? "powinnaś" : "powinneś"
                }}
                wypijać {{ parseInt(user.userConfig.water) }} ml, czyli ok.
                {{ parseInt(user.userConfig.water / 300) }} szklanek płynów
              </h6>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="mt-0 pl-3 pr-3">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert dense class="bg-gradient-light mt-2">
                  <h5 class="text-typo">
                    <v-icon>fas fa-bullseye mr-2</v-icon> Cele
                  </h5>
                  <span :style="'color: #424242'">
                    <ul>
                      <li
                        v-for="target in user.userConfig.targets"
                        :key="target"
                      >
                        {{ contentConfig.training_targets[target].label }}
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert dense class="bg-gradient-light mt-2">
                  <h5 class="text-typo">
                    <v-icon>fas fa-signal mr-2</v-icon> Poziom zaawansowania
                  </h5>
                  <DifficultyStars
                    :tooltip="true"
                    :rating="user.userConfig.loa"
                  ></DifficultyStars>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2 ml-2 mr-2"></v-divider>
          <v-row class="mt-0 pl-3 pr-3">
            <v-col cols="12">
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="tr in tableitems"
                  :key="tr.name"
                  :class="'bg-gradient-primary text-white'"
                >
                  <v-expansion-panel-header>{{
                    `${tr.label}: ${tr.value}`
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card
                      v-if="tr.values.length > 1"
                      class="mx-auto text-center"
                      color="purple accent-1"
                      max-width="600"
                    >
                      <v-card-text class="pl-0 pr-0 pt-2 pb-2">
                        <v-sheet color="purple accent-1">
                          <v-sparkline
                            :value="tr.values"
                            color="rgba(255, 255, 255, .7)"
                            height="100"
                            padding="24"
                            stroke-linecap="round"
                            smooth
                          >
                            <template v-slot:label="item">
                              {{ `${item.value}${tr.unit}` }}
                            </template>
                          </v-sparkline>
                        </v-sheet>
                      </v-card-text>
                    </v-card>
                    <span class="text-light text-center small" v-else>
                      Edytuj progres przyciskiem poniżej aby pokazać
                      wykres</span
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <div class="card-padding text-center">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-primary
                bg-gradient-primary
                py-2
                px-6
                me-2
                mt-3
                mb-2
                w-100
              "
              @click="goToAppSetup()"
              color="#5e72e4"
              :loading="false"
            >
              <span class="ml-1"
                ><v-icon size="12">fas fa-walking ms-2</v-icon> Edytuj postęp
              </span>
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import DifficultyStars from "@/components/util/DifficultyStars.vue";
export default {
  name: "MeDialog",
  data() {
    return {
      dialog: false,
      tableitems: [],
      value: [423, 446, 675, 510, 590, 610, 760],
    };
  },
  components: {
    DifficultyStars,
  },
  methods: {
    goToAppSetup: function () {
      this.dialog = false;
      if (this.$route.path !== "/appsetup") this.$router.push("/appsetup");
    },
    setTableItems: function () {
      let conf = this.user.userConfig;
      let h = conf.history;
      let c = this.user.userConfig.circuits;
      this.tableitems = [
        {
          label: `Twój wzrost`,
          value: conf.height > 0 ? `${conf.height}cm` : "Nie podano",
          unit: h.height.unit,
          values: h.height.values,
        },
        {
          label: `Twoja waga`,
          value: conf.weight > 0 ? `${conf.weight}kg` : "Nie podano",
          unit: h.weight.unit,
          values: h.weight.values,
        },
        {
          label: `Obwód - Klatka piersiowa`,
          value: c.chest > 0 ? `${c.chest}cm` : "Nie podano",
          unit: h.circuits.chest.unit,
          values: h.circuits.chest.values,
        },
        {
          label: `Obwód - Talia`,
          value: c.waist > 0 ? `${c.waist}cm` : "Nie podano",
          unit: h.circuits.waist.unit,
          values: h.circuits.waist.values,
        },
        {
          label: `Obwód - Biodra`,
          value: c.hips > 0 ? `${c.hips}cm` : "Nie podano",
          unit: h.circuits.hips.unit,
          values: h.circuits.hips.values,
        },
        {
          label: `Obwód - Ramię`,
          value: c.shoulder > 0 ? `${c.shoulder}cm` : "Nie podano",
          unit: h.circuits.shoulder.unit,
          values: h.circuits.shoulder.values,
        },
        {
          label: `Obwód - Udo`,
          value: c.thigh > 0 ? `${c.thigh}cm` : "Nie podano",
          unit: h.circuits.thigh.unit,
          values: h.circuits.thigh.values,
        },
      ];
    },
  },
  watch: {
    dialog: {
      handler: function () {
        if (!this.dialog) {
          this.$store.commit("setMeDialog", false);
        }
      },
    },
    meDialog: {
      handler: function () {
        if (this.meDialog) {
          this.dialog = this.meDialog;
        }
      },
    },
    user: {
      handler: function () {
        this.setTableItems();
      },
      deep:true
    },
  },
  created() {},
  mounted() {
    this.setTableItems();
  },
  computed: {
    meDialog() {
      return this.$store.state.meDialog;
    },
    user() {
      return this.$store.state.user;
    },
    contentConfig() {
      return this.$store.state.contentConfig;
    },
  },
};
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

.v-expansion-panel .v-icon {
  color: white !important;
}

g text {
  font-size: 0.7rem !important;
}
</style>
