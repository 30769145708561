<template>
  <div>
    <drawer
      :sidebarColor="`primary`"
      :sidebarTheme="sidebarTheme"
      :bottom="true"
      ref="drawer"
    >
    </drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="setDrawer($event)"
        :toggle-active="drawer"
      ></app-bar>
      <fade-transition :duration="500" origin="center top" mode="out-in">
        <slot />
      </fade-transition>
      <content-footer></content-footer>
      <ContentLocked></ContentLocked>
      <MeDialog></MeDialog>
    </v-main>
    
  </div>
  
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import ContentLocked from "@/components/util/ContentLocked.vue";
import MeDialog from "@/components/util/MeDialog.vue";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "default-layout",
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBar,
    ContentLocked,
    MeDialog
  },
  data() {
    return {
      drawer: null,
      sidebarColor: "success",
      sidebarTheme: "transparent",
      navbarFixed: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    setDrawer(val) {
      this.drawer = val;
      this.$store.commit("setDrawer", { value: this.drawer });
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
