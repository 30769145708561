<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "blank-layout",
  components: {},
};
</script>

<style></style>
