<template>
<div>
	 <p><a name="page1"></a><strong>REGULAMIN SERWISU</strong></p>

<p><strong>ViveFit by Paula</strong></p>

<p>Serwis internetowy <strong>ViveFit by Paula</strong>, zwany dalej <strong>SERWISEM</strong> prowadzony przez Trening Fitness Paula Piotrzkowska Spół ka z ograniczoną odpowiedzialnością, prowadząca dział alność pod adresem: ul. Browarowa 5, 83-200 Starogard Gdański, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przy Sądzie Rejonowym Gdańsk Pół noc w Gdańsku, VIII Wydział Gospodarczy pod numerem KRS 0000862318, REGON 38715214100000, NIP 5922282400 o kapitale zakł adowym w wysokości 5.000,00 PLN świadcząca usł ugi drogą elektroniczną za pośrednictwem <strong>SERWISU.</strong></p>

<p>§ <strong>1</strong></p>

<p><strong>DEFINICJE</strong></p>

<p><strong>ViveFit by Paula</strong> – tj. spół ka Trening Fitness Paula Piotrzkowska Spół ka z ograniczoną odpowiedzialnością, prowadząca dział alność pod adresem: ul. Browarowa 5, 83-200 Starogard Gdański, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przy Sądzie Rejonowym Gdańsk Pół noc w Gdańsku, VIII Wydział Gospodarczy pod numerem KRS: 0000862318, REGON 38715214100000, NIP 5922282400 o kapitale zakł adowym w wysokości 5.000,00 PLN</p>

<p><strong>SERWIS</strong> – serwis internetowy ViveFit by Paula, dział ający pod adresem <a href="http://vivefit.tv/">http://vivefit.tv/ </a>na skutek zawartej Umowy, umożliwia Użytkownikowi dostęp do <strong>MATERIAŁ ÓW</strong> na zasadach określonych</p>

<p><strong>UMOWĄ.</strong></p>

<p><strong>UMOWA</strong>- umowa zawierana na odległ ość drogą elektroniczną między ViveFit by Paula a<strong> UŻYTKOWNIKIEM</strong>, której treść określają postanowienia niniejszego <strong>REGULAMINU</strong>.</p>

<p><strong>UŻYTKOWNIK</strong> –osoba fizyczna posiadającą peł ną zdolność do czynności prawnych, która zawarł a UMOWĘ z SERWISEM, a także będąca konsumentem w rozumieniu art. 221 Kodeksu cywilnego korzystająca z <strong>SERWISU</strong>.</p>

<p><strong>CENNIK</strong> – aktualny cennik dla danego rodzaju <strong>ABONAMENTU</strong> znajdujący się na stronie</p>

<p><a href="http://vivefit.tv/">http://vivefit.tv/</a></p>

<p><strong>ABONAMENT –</strong> opł ata pobierana za korzystanie z usł ug <strong>SERWISU</strong>.</p>

<p><strong>MATERIAŁ Y –</strong> wszelkie treści publikowane w <strong>SERWISIE,</strong> które dostępne są pod adresem <a href="http://vivefit.tv/">http://vivefit.tv/ </a>, w szczególności materiał y audiowizualne.</p>

<p><strong>DOSTĘP –</strong> usł uga umożliwiająca <strong>UŻYTKOWNIKOWI</strong> korzystanie z <strong>MATERIAŁ ÓW</strong>.</p>

<br>
<p><a name="page2"></a><strong>SKRÓT</strong> – dostęp umożliwiający korzystanie z <strong>SERWISU</strong> oraz dostępu do <strong>MATERIAŁ ÓW</strong> objętych<strong> UMOWĄ,</strong> przeznaczone dla urządzeń przenośnych, takich jak: tablety, telefony komórkowe, smartfony, wyposażonych w odpowiednie systemy operacyjne.</p>

<p><strong>DIETA ViveFit -</strong> usł uga umożliwiająca <strong>UŻYTKOWNIKOWI</strong> korzystanie z przygotowanych przepisów posił ków znajdujących się w <strong>SERWISIE</strong>.</p>

<p><strong>§ 2</strong></p>

<p><strong>POSTANOWIENIA WSTĘPNE</strong></p>

<p>1. Niniejszy <strong>REGULAMIN</strong> określa szczegół owe zasady i warunki korzystania z <strong>SERWISU</strong>, zawierania <strong>UMOWY</strong>, w tym dostarczania <strong>MATERIAŁ ÓW.</strong></p>

<p>2. REGULAMIN jest nieodpł atnie dostępny na stronie internetowej: <a href="http://vivefit.tv/">http://vivefit.tv/</a></p>

<p>3. Zawarcie UMOWY jest jednoznaczne z akceptacją <strong>REGULAMINU (</strong>zgodnie z treścią art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usł ug drogą elektroniczną t.j. Dz.U. z 2020 r. poz. 344 ze zm.).</p>

<p>4. <strong>UŻYTKOWNIK</strong> przed rejestracją w <strong>SERWISIE</strong> powinien zapoznać się z <strong>REGULAMINEM</strong>.</p>

<p>5. Aby uzyskać dostęp do <strong>SERWISU</strong> w pierwszej kolejności należy dokonać w nim rejestracji.</p>

<p>6. Rejestracja polega na uzupeł nieniu pól: imię, e-mail, hasł o, potwierdź hasł o, wyrażenie zgody na Warunki Użytkownika oraz wybranie pola DOŁ ĄCZ.</p>

<p>7. <strong>UŻYTKOWNIK</strong> rejestrując konto w <strong>SERWISIE</strong> potwierdza, że zapoznał się z Warunkami Użytkownika oraz akceptuje je w cał ości i bez jakichkolwiek zastrzeżeń.</p>

<p>8. Użytkownikowi na adres e-mail zostaje wysł ana ankieta do wypeł nienia informacji takich jak: wiek, wzrost, waga, rodzaj trybu życia, poziom zaawansowania oraz cel.</p>

<p>9. Z momentem aktywacji konta <strong>UŻYTKOWNIKA</strong> rejestracja zostaje zakończona.</p>

<p>10. Kolejno po zakończeniu rejestracji <strong>UŻYTKOWNIKA</strong> w <strong>SERWISIE</strong>, wyboru oferty zgodnie z <strong>CENNIKIEM</strong> i dokonania zapł aty za dostęp do Usł ug <strong>SERWISU</strong> dochodzi do zawarcia <strong>UMOWY</strong> pomiędzy <strong>UŻYTKOWNIKIEM</strong> a <strong>SERWISEM</strong>.</p>

<p>11. Pł atności dokonuje się:</p>

<p><strong>a. </strong>za pośrednictwem platformy Stripe,</p>

<p><strong>b. </strong>za pośrednictwem platformy Przelewy24.</p>

<p>12. Po dokonaniu pł atności <strong>SERWIS</strong> przekazuje <strong>UŻYTKOWNIKOWI</strong> potwierdzenie zawarcia <strong>UMOWY</strong>.</p>

<p>13. Świadczenie Usł ugi trwa od momentu zawarcia <strong>UMOWY</strong> aż do momentu jej rozwiązania w wyniku upł ywu okresu na jaki został a zawarta.</p>

<p>14. W razie wystąpienia przyczyn niezależnych od <strong>ViveFit by Paula</strong>, termin rejestracji w <strong>SERWISIE</strong> może ulec wydł użeniu. W takim przypadku <strong>ViveFit by Paula</strong> jest zobowiązane niezwł ocznie zawiadomić o tym <strong>UŻYTKOWNIKA</strong> oraz wskazać nowy termin rejestracji.</p>

<p>15. Dostęp do <strong>SERWISU</strong> nastąpi niezwł ocznie po otrzymaniu przez <strong>ViveFit by Paula</strong> potwierdzenia akceptacji pł atności przez danego operatora.</p>

<br>
<p><a name="page3"></a>16. Ważność <strong>UMOWY</strong> trwa od momentu zaksięgowania na rachunku bankowym <strong>SERWISU</strong>,</p>

<p>z tym zastrzeżeniem, że po dokonaniu zapł aty za dostęp do konta brak jest możliwości zawieszenia pł atności w trakcie trwania abonamentu.</p>

<p><strong>§ 3</strong></p>

<p><strong>PRAWA AUTORSKIE</strong></p>

<p>1. <strong>UŻYTKOWNIK</strong> oświadcza, że rozumie, iż wszelkie treści dostępne w <strong>SERWISIE</strong> mają charakter utworu i podlegają ochronie przewidzianej ustawą o prawie autorskim i prawach pokrewnych z dnia 5 kwietnia 2017 r. (t.j. Dz.U. z 2020 r. poz. 344 ze zm.)</p>

<p>2. <strong>UŻYTKOWNIK</strong> w ramach zawartej umowy nie nabywa majątkowych praw autorskich do treści dostępnych w <strong>SERWISIE</strong>.</p>

<p>3. <strong>UŻYTKOWNIK</strong> upoważniony jest tylko i wył ącznie do wykorzystania treści dostępnych w <strong>SERWISIE </strong>we wł asnym zakresie i na wł asny użytek.</p>

<p>4. <strong>UŻYTKOWNIK</strong> nie ma prawa do publikowania, kopiowania, powielania treści dostępnych</p>

<p>w <strong>SERWISIE</strong> na potrzeby inne, niż wł asny użytek oraz do udostępniania i prezentowanie treści dostępnych w <strong>SERWISIE</strong> osobom trzecim;</p>

<p>5. Korzystanie z <strong>SERWISU</strong>, w tym z <strong>MATERIAŁ ÓW</strong>, jest dopuszczalne wył ącznie dla celów prywatnych.</p>

<p>6. <strong>SERWIS</strong> oraz każdy jego element, w szczególności grafika, zdjęcia, nagrania, przepisy, loga, które pojawiają się w <strong>SERWISIE</strong>, należą do <strong>ViveFit by Paula</strong> i tym samym podlegają ochronie prawnej.</p>

<p>7. <strong>UŻYTKOWNIK</strong> przekazując <strong>SERWISOWI</strong> w szczególności zdjęcia, filmy (Utwór – rozumieniu ustawy</p>

<p>o prawie autorskim i prawach pokrewnych z dnia 4 lutego 1994 r. t.j. Dz.U. z 2021 r. poz. 1062 ze zm.),</p>

<p><strong>UŻYTKOWNIK</strong> udziela <strong>ViveFit by Paula</strong> nieodpł atnej i nieograniczonej licencji do korzystania z</p>

<p>Utworu na następujących polach eksploatacji w stosunku do każdego z Utworów:</p>

<p>a. w zakresie utrwalania i zwielokrotniania utworu - wytwarzanie określoną techniką egzemplarzy utworu, w tym techniką drukarską, reprograficzną, zapisu magnetycznego oraz techniką cyfrową;</p>

<p>b. w zakresie obrotu oryginał em albo egzemplarzami, na których utwór utrwalono - wprowadzanie do obrotu, użyczenie lub najem oryginał u albo egzemplarzy;</p>

<p>c. w zakresie rozpowszechniania utworu w sposób inny niż określony w pkt § 3 ust. 10b - publiczne wykonanie, wystawienie, wyświetlenie, odtworzenie oraz nadawanie i reemitowanie, a także publiczne</p>

<p>udostępnianie utworu w taki sposób, aby każdy mógł mieć do niego dostęp w miejscu i w czasie przez siebie wybranym.</p>

<p><strong>§ 4</strong></p>

<p><strong>WARUNKI TECHNICZNE</strong></p>

<p>1. <strong>SERWIS</strong> jest dostępny wył ącznie na Platformach internetowych obsł ugujących oprogramowanie JavaScript, z ł ączem minimum 3mbit/s.</p>

<p>2. <strong>UŻYTKOWNIK</strong> może jednocześnie korzystać z treści dostępnych w <strong>SERWISIE</strong> poprzez zalogowanie na Platformie internetowej.</p>

<br>
<p><a name="page4"></a>3. Zarejestrowanie Platformy internetowej następuje poprzez zalogowanie się przez <strong>UŻYTKOWNIKA</strong> do swojego konta w <strong>SERWISIE.</strong></p>

<p>4. Obowiązkiem <strong>UŻYTKOWNIKA</strong>, przed rejestracją w <strong>SERWISIE</strong> i zawarciem <strong>UMOWY</strong> jest dokonanie sprawdzenia ł ącza oraz ustalenia czy posiadana przez niego przeglądarka internetowe speł nia wszelkie wymogi techniczne określone w <strong>REGULAMINIE</strong>.</p>

<p>5. <strong>SERWIS</strong> wymaga stał ego nadzoru technicznego, w szczególności bieżącej konserwacji, co może wiązać się z czasowym i krótkotrwał ym przerwaniem dostępu do <strong>SERWISU</strong>, ze względów bezpieczeństwa, w tym nieprawidł owego korzystania z <strong>SERWISU</strong> na czas niezbędny do przeprowadzenia prac bądź usunięcia nieprawidł owości.</p>

<p>6. W przypadku przerw w możliwości dostępu z powodu awarii, <strong>SERWIS</strong> doł oży należytych starań, by</p>

<p>usunąć awarię w najkrótszym możliwym czasie.</p>

<p>7. <strong>SERWIS</strong> nie ponosi odpowiedzialności za awarię sprzętu, który używany jest do korzystania</p>

<p>z <strong>SERWISU.</strong></p>

<p><strong>§ 5</strong></p>

<p><strong>POSTĘPOWANIE REKLAMACYJNE</strong></p>

<p>1. <strong>UŻYTKOWNIK</strong> może kontaktować się z <strong>SERWISEM</strong> w sprawie usł ug świadczonych drogą elektroniczną na podstawie niniejszego Regulaminu w formie elektronicznej poprzez wysł anie zgł oszenia za pośrednictwem formularza kontaktowego na stronie <a href="http://vivefit.tv/">http://vivefit.tv/</a></p>

<p>2. <strong>SERWIS</strong> może kontaktować się z <strong>UŻYTKOWNIKIEM</strong> w formie elektronicznej na wskazany przez<strong> UŻYTKOWNIKA</strong> adres e-mail.</p>

<p>3. W przypadku, gdy zdaniem <strong>UŻYTKOWNIKA</strong> usł ugi świadczone na podstawie <strong>REGULAMINU</strong> nie są realizowane przez <strong>SERWIS</strong> lub są realizowane niezgodnie z postanowieniami <strong>REGULAMINU</strong>, może on zł ożyć zastrzeżenia w sposób określony poniżej (Reklamacja).</p>

<p>4. Reklamację można zł ożyć w formie elektronicznej za pomocą formularza kontaktowego. Reklamacja powinna zawierać co najmniej nazwę, pod jaką <strong>UŻYTKOWNIK</strong> opis zgł aszanych zastrzeżeń oraz wskazanie proponowanego sposobu rozstrzygnięcia Reklamacji.</p>

<p>5. <strong>SERWIS</strong> rozpoznaje Reklamację w terminie 30 dni od daty jej otrzymania. W przypadku, gdy Reklamacja nie zawiera informacji koniecznych do jej rozpatrzenia, <strong>SERWIS</strong> zwróci się do skł adającego Reklamację o jej uzupeł nienie w niezbędnym zakresie, a termin 30 dni biegnie wówczas od dnia doręczenia uzupeł nionej Reklamacji.</p>

<p>6. Odpowiedź na Reklamację wysył ana jest wył ącznie na adres e-mail przypisany do Konta danego <strong>UŻYTKOWNIKA</strong>. W szczególnie uzasadnionych przypadkach <strong>SERWIS</strong> może wysł ać odpowiedź nainny, wskazany przez skł adającego Reklamację, adres e-mail, który nie jest przypisany do</p>

<p><strong>UŻYTKOWNIKA</strong>.</p>

<p>7. W przypadku nierozpatrzenia reklamacji w terminie 30 dni od dnia jej wniesienia, uważa się, że reklamacja został a uwzględniona.</p>

<p>8. Użytkownik ma prawo dochodzenia roszczeń w postępowaniu sądowym przed wł aściwym miejscowo i rzeczowo sądem, bez konieczności korzystania z sądownictwa polubownego.</p>

<br>
<p><a name="page5"></a><strong>§ 6</strong></p>

<p><strong>OBOWIĄZKI UŻYTKOWNIKA</strong></p>

<p>1. <strong>UŻYTKOWNIK</strong> ma obowiązek korzystania z <strong>SERWISU</strong> w sposób zgodny z <strong>REGULAMINEM</strong> oraz obowiązującymi przepisami prawa i zasadami współ życia społ ecznego.</p>

<p>2. <strong>UŻYTKOWNIK</strong> jest obowiązany do korzystania z treści dostępnych w <strong>SERWISIE</strong> w sposób zgodny z prawem i zasadami współ życia społ ecznego.</p>

<p>3. Użytkownik jest zobowiązany do doł ożenia szczególnej staranności przy zachowaniu poufności utworzonego loginu oraz hasł a. Użytkownik ponosi peł ną odpowiedzialność za udostępnienie tych informacji osobom trzecim.</p>

<p>4. Użytkownik zobowiązuje się do poinformowania wł aściciela Serwisu o każdorazowej zmianie adresu e-mail pod rygorem uznania doręczenia na wskazany przy rejestracji adres e-mail za skuteczne.</p>

<p>5. Dostęp do <strong>SERWISU</strong> może zostać <strong>UŻYTKOWNIKOWI</strong> zablokowany w sytuacji, gdy <strong>UŻYTKOWNIK</strong>:</p>

<p>a. udostępnia login lub hasł o do swojego konta w <strong>SERWISIE</strong> innym <strong>UŻYTKOWNIKOM</strong> lub innym nieuprawnionym osobom;</p>

<p>b. wykorzystuje <strong>SERWIS</strong> do celów niezgodnych z treścią <strong>REGULAMINU</strong>, w szczególności, gdy użytkownik wykorzystuje treści w sposób sprzeczny z ich przeznaczeniem lub na szkodę osób trzecich;</p>

<p>c. korzysta z kont innych <strong>UŻYTKOWNIKÓW,</strong></p>

<p>d. w jakikolwiek sposób ingeruje w funkcjonowanie <strong>SERWISU</strong>;</p>

<p>e. nagrywa lub w inny sposób utrwala treści znajdujące się w <strong>SERWISIE</strong> bądź wprowadza je w obrót i umieszcza na innych serwerach;</p>

<p>6. Zablokowanie dostępu do <strong>SERWISU</strong> jest równoznaczne z rozwiązaniem <strong>UMOWY</strong> w trybie natychmiastowym.</p>

<p>7. <strong>UŻYTKOWNIK</strong> może korzystać z <strong>SERWISU</strong> tylko i wył ącznie gdy jest stan zdrowia umożliwiania mu korzystanie z treści dostępnych w <strong>SERWISIE.</strong></p>

<p>8. <strong>UŻYTKOWNIK</strong> korzysta z <strong>SERWISU</strong> tylko i wył ącznie dla celów prywatnych.</p>

<p>9. Dział ania <strong>UŻYTKOWNIKA</strong> wykraczające poza udzieloną licencję stanowią naruszenie <strong>REGULAMINU</strong> i skutkują rozwiązaniem <strong>UMOWY</strong> w trybie natychmiastowym.</p>

<p>10. Przed skorzystaniem z <strong>SERWISU UŻYTKOWNIK</strong> musi być świadomy swojego stanu zdrowia.</p>

<p>11. <strong>SERWIS</strong> zastrzega, iż przed rozpoczęciem korzystania z <strong>SERWISU, UŻYTKOWNIK</strong> winien skonsultować swój stan zdrowia z lekarzem, w celu wykluczenia przeciwwskazań do stosowania oraz wykonywania ćwiczeń fizycznych udostępnionych w <strong>SERWISIE.</strong></p>

<p>12. <strong>SERWIS</strong> nie świadczy żadnych usł ug medycznych.</p>

<p>13. <strong>SERWISU</strong> ma prawo do usuwania treści udostępnianych w <strong>SERWISIE,</strong> w tym komentarzy, które uzna za obraźliwe, bez podania przyczyny ani informowania o tym <strong>UŻYTKOWNIKA.</strong></p>

<p>§ <strong>7</strong></p>

<p><strong>ODSTĄPIENIE OD UMOWY</strong></p>

<strong><br>
</strong><p><a name="page6"></a>1. Zważywszy, że <strong>UŻYTKOWNIK zawiera UMOWĘ na odległ ość tj.</strong> umowę zawartą z konsumentem w ramach zorganizowanego systemu zawierania umów na odległ ość, bez jednoczesnej fizycznej obecności stron, z wył ącznym wykorzystaniem jednego lub większej liczby środków porozumiewania się na odległ ość do chwili zawarcia umowy wł ącznie <strong>UŻYTKOWNIK</strong> W terminie 14 dni od zawarcia umowy,</p>

<p>może od niej odstąpić bez podania przyczyn. Wzór formularza</p>

<p>o odstąpieniu od umowy, z którego <strong>UŻYTKOWNIK</strong> może skorzystać, stanowią Zał ącznik nr 1 do</p>

<p><strong>REGULAMINU</strong>.</p>

<p>2. <strong>SERWIS</strong> niezwł ocznie, nie później niż w terminie 14 dni, od dnia zł ożenia oświadczenia o odstąpieniu od <strong>UMOWY</strong> zwróci przekazane na jego rzecz środki finansowe.</p>

<p>3. Zwrot pł atności nastąpi w takiej samej formie pł atności, w jakiej dokonano pł atności.</p>

<p>§ <strong>8</strong></p>

<p><strong>POSTANOWIENIA KOŃCOWE</strong></p>

<p>1. Podczas korzystania z <strong>SERWISU</strong> w urządzeniu <strong>UŻYTKOWNIKA</strong> są zapisywane informacje zwane „plikami cookies”. Instalacja powyższego wymagana do prawidł owego dział ania <strong>SERWISU</strong>, a szczegół owe informacje dostępne są na <a href="http://vivefit.tv/">http://vivefit.tv/</a></p>

<p>2. Administratorem danych osobowych <strong>UŻYTKOWNIKÓW SERWISU</strong> jest Trening Fitness Paula Piotrzkowska Spół ka z ograniczoną odpowiedzialnością, prowadząca dział alność pod adresem: ul. Browarowa 5, 83-200 Starogard Gdański, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przy Sądzie Rejonowym Gdańsk Pół noc w Gdańsku, VIII Wydział Gospodarczy pod numerem KRS: 0000862318, REGON 38715214100000, NIP 5922282400 o kapitale zakł adowym w wysokości 5.000,00 PLN.</p>

<p>3. Wszelkie informacje dotyczące przetwarzania danych osobowych w związku ze świadczeniem usł ug za pośrednictwem <strong>SERWISU</strong> znajdują się w Polityce Prywatności na stronie <a href="https://vivefit.tv/regulamin">https://vivefit.tv/regulamin</a></p>

<p>4. <strong>REGULAMIN</strong> może podlegać zmianom a <strong>SERWIS</strong> informuje o zmianie <strong>REGULAMINU</strong> za pośrednictwem strony internetowej serwisu <a href="http://vivefit.tv/">http://vivefit.tv/ </a>, a także poprzez przeslanie wiadomości dotyczącej zmiany na adres poczty elektronicznej <strong>UŻYTKOWNIKA</strong>. Zmiany w <strong>REGULAMINIE</strong></p>

<p>wywoł ują skutek od dnia zamieszczenia informacji o zmianie, chyba, że postanowiono inaczej.</p>

<p>5. W przypadku, gdy <strong>UŻYTKOWNIK</strong> nie akceptuje zmiany <strong>REGULAMINU</strong>, ma prawo do wypowiedzenia <strong>UMOWY</strong> ze skutkiem natychmiastowym.</p>

<p>6. Wypowiedzenie należy zł ożyć w formie, w której zawarto <strong>UMOWĘ</strong>, nie później niż w terminie 14 dni od poinformowania o zmianie.</p>

<p>7. Ewentualne spory powstał e pomiędzy SERWISEM a UŻYTKOWNIKIEM, zostają poddane sądom wł aściwym.</p>

<br>
<p><a name="page7"></a> </p>

<p><strong>ZAŁ ĄCZNIK Nr 1 do Regulaminu</strong></p>

<p>____________, dnia ___________</p>

<p>__________________</p>

<p>Imię i nazwisko</p>

<p>__________________</p>

<p>adres</p>

<p>__________________</p>

<p>adres e-mail</p>

<p><strong>Oświadczenie</strong></p>

<p><strong>o odstąpieniu od umowy zawartej na odległ ość</strong></p>

<p>Ja _____________________________niniejszym informuję o odstąpieniu od umowy</p>

<p>___________________________ zawartej w dniu _________________</p>

<p>________________________</p>

<p>Podpis konsumenta</p>

<br>
<p><a name="page8"></a> </p>

<br>
<p><a name="page9"></a><strong>Podgląd Polityki Prywatności</strong></p>

<p><strong>Polityka Prywatności</strong></p>

<p>Poniższa Polityka Prywatności określa <strong>zasady zapisywania i</strong></p>

<p><strong>uzyskiwania dostępu do danych na Urządzeniach</strong></p>

<p><strong>Użytkowników</strong> korzystających z Serwisu do celów świadczenia usług</p>

<p>drogą elektroniczną przez Administratora oraz <strong>zasady gromadzenia</strong></p>

<p><strong>i przetwarzania danych osobowych Użytkowników</strong>, które zostały</p>

<p>podane przez nich osobiście i dobrowolnie za pośrednictwem narzędzi</p>

<p>dostępnych w Serwisie.</p>

<p>Poniższa Polityka Prywatności jest integralną częścią <a href="https://nety.pl/regulamin">Regulaminu</a> <a href="https://nety.pl/regulamin">Serwisu</a>, który określa zasady, prawa i obowiązki Użytkowników korzystających z Serwisu.</p>

<p><strong>§1 Definicje</strong></p>

<p>• <strong>Serwis</strong> - serwis internetowy "ViveFit by Paula" działający pod adresem http://vivefit.tv/</p>

<p>• <strong>Serwis zewnętrzny</strong> - serwisy internetowe partnerów, usługodawców lub usługobiorców współpracujących z Administratorem</p>

<p>• <strong>Administrator Serwisu / Danych</strong> - Administratorem Serwisu oraz Administratorem Danych (dalej Administrator) jest firma "Trening Fitness Paula Piotrzkowska Sp.z.o.o", prowadząca działalność pod adresem: ul. Browarowa 5, 83-200 Starogard Gdański, o nadanym numerze identyfikacji podatkowej (NIP): 5922282400, o nadanym numerze KRS: 0000862318, świadcząca usługi drogą elektroniczną za pośrednictwem Serwisu</p>

<p>• <strong>Użytkownik</strong> - osoba fizyczna, dla której Administrator świadczy usługi drogą elektroniczną za pośrednictwem Serwisu.</p>

<strong><br>
</strong><p><a name="page10"></a>• <strong>Urządzenie</strong> - elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem którego Użytkownik uzyskuje dostęp do Serwisu</p>

<p>• <strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w formie plików zamieszczanych na Urządzeniu Użytkownika</p>

<p>• <strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)</p>

<p>• <strong>Dane osobowe</strong> - oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej</p>

<p>• <strong>Przetwarzanie</strong> - oznacza operację lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie;</p>

<p>• <strong>Ograniczenie przetwarzania</strong> - oznacza oznaczenie przechowywanych danych osobowych w celu ograniczenia ich przyszłego przetwarzania</p>

<p>• <strong>Profilowanie</strong> - oznacza dowolną formę zautomatyzowanego przetwarzania danych osobowych, które polega na wykorzystaniu danych osobowych do oceny niektórych czynników osobowych osoby fizycznej, w szczególności do analizy lub prognozy aspektów dotyczących efektów pracy tej osoby fizycznej, jej</p>

<strong><br>
</strong><p><a name="page11"></a>sytuacji ekonomicznej, zdrowia, osobistych preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub przemieszczania się</p>

<p>• <strong>Zgoda</strong> - zgoda osoby, której dane dotyczą oznacza dobrowolne, konkretne, świadome i jednoznaczne okazanie woli, którym osoba, której dane dotyczą, w formie oświadczenia lub wyraźnego działania potwierdzającego, przyzwala na przetwarzanie dotyczących jej danych osobowych</p>

<p>• <strong>Naruszenie ochrony danych osobowych</strong> - oznacza naruszenie bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z prawem zniszczenia, utracenia, zmodyfikowania, nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych przesyłanych, przechowywanych lub w inny sposób przetwarzanych</p>

<p>• <strong>Pseudonimizacja</strong> - oznacza przetworzenie danych osobowych w taki sposób, by nie można ich było już przypisać konkretnej osobie, której dane dotyczą, bez użycia dodatkowych informacji, pod warunkiem że takie dodatkowe informacje są przechowywane osobno i są objęte środkami technicznymi i organizacyjnymi uniemożliwiającymi ich przypisanie zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej</p>

<p>• <strong>Anonimizacja</strong> - Anonimizacja danych to nieodwracalny proces operacji na danych, który niszczy / nadpisuje "dane osobowe" uniemożliwiając identyfikację, lub powiązanie danego rekordu z konkretnym użytkownikiem lub osobą fizyczną.</p>

<p><strong>§2 Inspektor Ochrony Danych</strong></p>

<p>Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora Ochrony Danych.</p>

<p>W sprawach dotyczących przetwarzania danych, w tym danych osobowych, należy kontaktować się bezpośrednio z Administratorem.</p>

<br>
<p><a name="page12"></a><strong>§3 Rodzaje Plików Cookies</strong></p>

<p>• <strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z</p>

<p>Urządzenia Użytkownika przez system teleinformatyczny Serwisu</p>

<p>• <strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów zewnętrznych. Skrypty Serwisów zewnętrznych, które mogą umieszczać pliki Cookies na Urządzeniach Użytkownika zostały świadomie umieszczone w Serwisie poprzez skrypty i usługi udostępnione i zainstalowane w Serwisie</p>

<p>• <strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.</p>

<p>• <strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia chyba że konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji Urządzenia.</p>

<p><strong>§4 Bezpieczeństwo składowania danych</strong></p>

<p>• <strong>Mechanizmy składowania i odczytu plików Cookie</strong> - Mechanizmy składowania, odczytu i wymiany danych pomiędzy Plikami Cookies zapisywanymi na Urządzeniu Użytkownika a Serwisem są realizowane poprzez wbudowane mechanizmy przeglądarek internetowych i nie pozwalają na pobieranie innych danych z Urządzenia Użytkownika lub danych innych witryn internetowych, które odwiedzał Użytkownik, w tym danych osobowych ani informacji poufnych. Przeniesienie na Urządzenie Użytkownika wirusów, koni trojańskich oraz innych robaków jest także praktycznie niemożliwe.</p>

<strong><br>
</strong><p><a name="page13"></a>• <strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora pliki Cookie są bezpieczne dla Urządzeń Użytkowników i nie zawierają skryptów, treści lub informacji mogących zagrażać bezpieczeństwu danych osobowych lub bezpieczeństwu Urządzenia z którego korzysta Użytkownik.</p>

<p>• <strong>Cookie zewnętrzne</strong> - Administrator dokonuje wszelkich</p>

<p>możliwych działań w celu weryfikacji i doboru partnerów serwisu w kontekście bezpieczeństwa Użytkowników. Administrator do współpracy dobiera znanych, dużych partnerów o globalnym zaufaniu społecznym. Nie posiada on jednak pełnej kontroli nad zawartością plików Cookie pochodzących od zewnętrznych partnerów. Za bezpieczeństwo plików Cookie, ich zawartość oraz zgodne z licencją wykorzystanie przez zainstalowane w serwisie Skrypty, pochodzących z Serwisów zewnętrznych, Administrator nie ponosi odpowiedzialności na tyle na ile pozwala na to prawo. Lista partnerów zamieszczona jest w dalszej części Polityki Prywatności.</p>

<p>• <strong>Kontrola plików Cookie</strong></p>

<p>◦ Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące zapisywania, usuwania oraz dostępu do danych zapisanych plików Cookies przez każdą witrynę internetową</p>

<p>◦ Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych przeglądarkach komputerowych dostępne są na stronie: <a href="https://nety.pl/jak-wylaczyc-pliki-cookie/">jak wyłączyć cookie </a>lub u jednego ze wskazanych dostawców:</p>

<p>▪ <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=pl">Zarządzanie plikami cookies w przeglądarce <strong>Chrome</strong></a></p>

<p>▪ <a href="https://help.opera.com/pl/latest/web-preferences/">Zarządzanie plikami cookies w przeglądarce <strong>Opera</strong></a></p>

<p>▪ <a href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek">Zarządzanie plikami cookies w przeglądarce <strong>FireFox</strong></a></p>

<p>▪ <a href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies">Zarządzanie plikami cookies w przeglądarce <strong>Edge</strong></a></p>

<p>▪ <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">Zarządzanie plikami cookies w przeglądarce <strong>Safari</strong></a></p>

<p>▪ <a href="#ie=ie-11">Zarządzanie plikami cookies w przeglądarce <strong>Internet</strong></a></p>

<p><a href="#ie=ie-11"><strong>Explorer 11</strong></a></p>

<p><strong>◦</strong></p>

<strong><br>
</strong><p><a name="page14"></a>◦ Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookie korzystając z narzędzi Urządzenia Użytkownika, za pośrednictwem którego Użytkownik korzysta z usług Serwisu.</p>

<p><strong>•</strong></p>

<p>• <strong>Zagrożenia po stronie Użytkownika</strong> - Administrator stosuje wszelkie możliwe środki techniczne w celu zapewnienia bezpieczeństwa danych umieszczanych w plikach Cookie. Należy jednak zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych zależy od obu stron w tym działalności Użytkownika. Administrator nie bierze odpowiedzialności za przechwycenie tych danych, podszycie się pod sesję Użytkownika lub ich usunięcie, na skutek świadomej lub nieświadomej działalność Użytkownika, wirusów, koni trojańskich i innego oprogramowania szpiegującego, którymi może jest lub było zainfekowane Urządzenie Użytkownika. Użytkownicy w celu zabezpieczenia się przed tymi zagrożeniami powinni stosować się do <a href="https://nety.pl/cyberbezpieczenstwo/">zasad</a> <a href="https://nety.pl/cyberbezpieczenstwo/">bezpiecznego korzystania z internetu</a>.</p>

<p>• <strong>Przechowywanie danych osobowych</strong> - Administrator zapewnia, że dokonuje wszelkich starań, by przetwarzane dane osobowe wprowadzone dobrowolnie przez Użytkowników były bezpieczne, dostęp do nich był ograniczony i realizowany zgodnie z ich przeznaczeniem i celami przetwarzania. Administrator zapewnia także, że dokonuje wszelkich starań w celu zabezpieczenia posiadanych danych przed ich utratą, poprzez stosowanie odpowiednich zabezpieczeń fizycznych jak i organizacyjnych.</p>

<p>• <strong>Przechowywanie haseł</strong> - Administrator oświadcza, że hasła przechowywane są w zaszyfrowanej postaci, używając najnowszych standardów i wytycznych w tym zakresie. Deszyfracja podawanych w Serwisie haseł dostępu do konta jest praktycznie niemożliwa.</p>

<strong><br>
</strong> <p><strong>§5 Cele do których wykorzystywane są pliki Cookie</strong></p>

<strong><br>
</strong><p><a name="page15"></a>• Usprawnienie i ułatwienie dostępu do Serwisu</p>

<p>• Personalizacja Serwisu dla Użytkowników</p>

<p>• Umożliwienie Logowania do serwisu</p>

<p>• Marketing, Remarketing w serwisach zewnętrznych</p>

<p>• Usługi serwowania reklam</p>

<p>• Usługi afiliacyjne</p>

<p>• Prowadzenie statystyk (użytkowników, ilości odwiedzin, rodzajów urządzeń, łącze itp.)</p>

<p>• Serwowanie usług multimedialnych</p>

<p>• Świadczenie usług społecznościowych</p>

<p><strong>§6 Cele przetwarzania danych osobowych</strong></p>

<p>Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane w jednym z następujących celów:</p>

<p>• Realizacji usług elektronicznych:</p>

<p>◦ Usługi rejestracji i utrzymania konta Użytkownika w Serwisie i funkcjonalności z nim związanych</p>

<p>◦ Usługi Newslettera (w tym przesyłania za zgodą treści reklamowych)</p>

<p>◦ Usługi udostępniania informacji o treści umieszczonych w Serwisie w serwisach społecznościowych lub innych witrynach.</p>

<p>• Komunikacji Administratora z Użytkownikami w sprawach związanych z Serwisem oraz ochrony danych</p>

<p>• Zapewnienia prawnie uzasadnionego interesu Administratora Dane o Użytkownikach gromadzone anonimowo i automatycznie są przetwarzane w jednym z następujących celów:</p>

<p>• Prowadzenie statystyk</p>

<p>• Remarketing</p>

<p>• Serwowanie reklam dostosowanych do preferencji Użytkowników</p>

<p>• Obsługi programów afiliacyjnych</p>

<p>• Zapewnienia prawnie uzasadnionego interesu Administratora</p>

<br>
<p><strong>§7 Pliki Cookies Serwisów</strong></p>

<p><strong>zewnętrznych</strong></p>

<strong><br>
</strong><p><a name="page16"></a>Administrator w Serwisie wykorzystuje skrypty javascript i komponenty webowe partnerów, którzy mogą umieszczać własne pliki cookies na Urządzeniu Użytkownika. Pamiętaj, że w ustawieniach swojej przeglądarki możesz sam decydować o dozwolonych plikach cookies jakie mogą być używane przez poszczególne witryny internetowe. Poniżej znajduje się lista partnerów lub ich usług zaimplementowanych w Serwisie, mogących umieszczać pliki cookies:</p>

<p>• <strong>Usługi multimedialne:</strong></p>

<p>◦ <a href="https://vimeo.com/terms">Vimeo</a></p>

<p>• <strong>Usługi społecznościowe / łączone:</strong></p>

<p>(Rejestracja, Logowanie, udostępnianie treści, komunikacja, itp.)</p>

<p>◦ <a href="https://www.facebook.com/legal/terms">Facebook</a></p>

<p>◦ <a href="https://policies.google.com/privacy?hl=pl">Google+</a></p>

<p>• <strong>Usługi udostępniania treści:</strong></p>

<p>◦ <a href="https://help.instagram.com/519522125107875">Instagram</a></p>

<p>• <strong>Usługi Newslettera:</strong></p>

<p>◦ <a href="https://mailchimp.com/legal/privacy/">MailChimp</a></p>

<p>◦ <a href="https://www.sendinblue.com/legal/privacypolicy/">SendInBlue</a></p>

<p>• <strong>Usługi serwowania reklam oraz sieci afiliacyjne:</strong></p>

<p>◦ <a href="https://policies.google.com/privacy?hl=pl">Google Adsense</a></p>

<p>• <strong>Prowadzenie statystyk:</strong></p>

<p>◦ <a href="https://policies.google.com/privacy?hl=pl">Google Analytics</a></p>

<p>◦ <a href="https://automattic.com/privacy/">WordPress Stats (Automattic Inc.)</a></p>

<p>◦ <a href="https://www.facebook.com/privacy/explanation">Facebook Analytics for Apps</a></p>

<p>Usługi świadczone przez podmioty trzecie są poza kontrolą Administratora. Podmioty te mogą w każdej chwili zmienić swoje warunki świadczenia usług, polityki prywatności, cel przetwarzania danych oraz sposów wykorzystywania plików cookie.</p>

<p><strong>§8 Rodzaje gromadzonych danych</strong></p>

<p>Serwis gromadzi dane o Użytkownikach. Cześć danych jest gromadzona automatycznie i anonimowo, a część danych to dane osobowe podane dobrowolnie przez Użytkowników w trakcie zapisywania się do poszczególnych usług oferowanych przez Serwis.</p>

<p><strong>Anonimowe dane gromadzone automatycznie:</strong></p>

<strong><br>
</strong><p><a name="page17"></a>• Adres IP</p>

<p>• Typ przeglądarki</p>

<p>• Rozdzielczość ekranu</p>

<p>• Przybliżona lokalizacja</p>

<p>• Otwierane podstrony serwisu</p>

<p>• Czas spędzony na odpowiedniej podstronie serwisu</p>

<p>• Rodzaj systemu operacyjnego</p>

<p>• Adres poprzedniej podstrony</p>

<p>• Adres strony odsyłającej</p>

<p>• Język przeglądarki</p>

<p>• Prędkość łącza internetowego</p>

<p>• Dostawca usług internetowych</p>

<p>• Dane demograficzne (wiek, płeć)</p>

<p><strong>Dane gromadzone podczas rejestracji:</strong></p>

<p>• Imię / nazwisko / pseudonim</p>

<p>• Login</p>

<p>• Adres e-mail</p>

<p>• Płeć</p>

<p>• Data urodzenia / wiek</p>

<p>• Adresy do profili społecznościowych</p>

<p>• Adres IP (zbierane automatycznie)</p>

<p>• Inne dane zwykłe</p>

<p><strong>Dane gromadzone podczas zapisu do usługi Newsletter</strong></p>

<p>• Imię / nazwisko / pseudonim</p>

<p>• Adres e-mail</p>

<p>• Adres IP (zbierane automatycznie)</p>

<p><strong>Dane gromadzone podczas dodawania komentarza</strong></p>

<p>• Imię i nazwisko / pseudonim</p>

<p>• Adres e-mail</p>

<p>• Adres www</p>

<p>• Adres IP (zbierane automatycznie)</p>

<p>Część danych (bez danych identyfikujących) może być przechowywana w plikach cookies. Cześć danych (bez danych identyfikujących) może być przekazywana do dostawcy usług statystycznych.</p>

<br>
<p><a name="page18"></a><strong>§9 Dostęp do danych osobowych przez podmioty trzecie</strong></p>

<p>Co do zasady jedynym odbiorcą danych osobowych podawanych przez Użytkowników jest Administrator. Dane gromadzone w ramach świadczonych usług nie są przekazywane ani odsprzedawane podmiotom trzecim.</p>

<p>Dostęp do danych (najczęściej na podstawie Umowy powierzenia przetwarzania danych) mogą posiadać podmioty, odpowiedzialne za utrzymania infrastruktury i usług niezbędnych do prowadzenia serwisu tj.:</p>

<p>• Firmy hostingowe, świadczące usługi hostingu lub usług pokrewnych dla Administratora</p>

<p>• Firmy, za pośrednictwem których świadczona jest usługa Newslettera</p>

<p>• Firmy pośredniczące w płatnościach on-line za towaru lub usługi oferowane w ramach Serwisu (w przypadku dokonywania transakcji zakupu w Serwisie)</p>

<p><strong>Powierzenie przetwarzania danych osobowych - Newsletter</strong></p>

<p>Administrator w celu świadczenia usługi Newsletter, korzysta z usług podmiotu trzeciego - serwisu <a href="https://mailchimp.com/legal/privacy/"><strong>MailChimp</strong></a><strong>, </strong><a href="https://www.sendinblue.com/legal/privacypolicy/"><strong>SendInBlue</strong></a><strong>,</strong> . Dane wprowadzone w formularzu zapisu do newslettera są przekazywane, przechowywane i przetwarzane w serwisie zewnętrznym tego usługodawcy.</p>

<p>Informujemy, że wskazany partner może modyfikować wskazaną politykę prywatności bez zgody Administratora.</p>

<p><strong>Powierzenie przetwarzania danych osobowych - Usługi Hostingu, VPS lub Serwerów Dedykowanych</strong></p>

<p>Administrator w celu prowadzenia serwisu korzysta z usług</p>

<p>zewnętrznego dostawcy hostingu, VPS lub Serwerów Dedykowanych -</p>

<br>
<p><a name="page19"></a><strong>OVH sp. z o.o.</strong>. Wszelkie dane gromadzone i przetwarzane w</p>

<p>serwisie są przechowywane i przetwarzane w infrastrukturze</p>

<p>usługodawcy zlokalizowanej w Polsce. Istnieje możliwość dostępu do</p>

<p>danych wskutek prac serwisowych realizowanych przez personel</p>

<p>usługodawcy. Dostęp do tych danych reguluje umowa zawarta</p>

<p>pomiędzy Administratorem a Usługodawcą.</p>

<p><strong>Przetwarzanie danych w przypadku płatności on-line</strong></p>

<p>W przypadku realizacji płatności on-line, wszelkie dane dotyczące płatności przekazywane są bezpośrednio przez Użytkownika podmiotowi realizującemu płatność - Przelewy24, Stripe. Wybrane dane niezbędne do realizacji transakcji są następnie przekazywane przez ten podmiot Administratorowi. Przekazanie danych reguluje umowa zawarta pomiędzy Administratorem a Usługodawcą.</p>

<p><strong>§10 Sposób przetwarzania danych osobowych</strong></p>

<p><strong>Dane osobowe podane dobrowolnie przez Użytkowników:</strong></p>

<p>• Dane osobowe nie będą przekazywane poza Unię Europejską, chyba że zostały opublikowane na skutek indywidualnego działania Użytkownika (np. wprowadzenie komentarza lub wpisu), co sprawi, że dane będą dostępne dla każdej osoby odwiedzającej serwis.</p>

<p>• Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji (profilowania).</p>

<p>• Dane osobowe nie będą odsprzedawane podmiotom trzecim.</p>

<p><strong>Dane anonimowe (bez danych osobowych) gromadzone automatycznie:</strong></p>

<p>• Dane anonimiwe (bez danych osobowych) będą przekazywane poza Unię Europejską.</p>

<br>
<p><a name="page20"></a>• Dane anonimiwe (bez danych osobowych) nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji (profilowania).</p>

<p>• Dane anonimiwe (bez danych osobowych) nie będą odsprzedawane podmiotom trzecim.</p>

<p><strong>§11 Podstawy prawne przetwarzania danych osobowych</strong></p>

<p>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</p>

<p>• Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)</p>

<p>◦ art. 6 ust. 1 lit. a</p>

<p>osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych celów</p>

<p>◦ art. 6 ust. 1 lit. b</p>

<p>przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy</p>

<p>◦ art. 6 ust. 1 lit. f</p>

<p>przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią</p>

<p>• Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U. 2018 poz. 1000)</p>

<p>• Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800)</p>

<p>• Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. 1994 Nr 24 poz. 83)</p>

<p><strong>§12 Okres przetwarzania danych osobowych</strong></p>

<p><strong>Dane osobowe podane dobrowolnie przez Użytkowników:</strong></p>

<p>Co do zasady wskazane dane osobowe są przechowywane wyłącznie przez okres świadczenia Usługi w ramach Serwisu przez</p>

<br>
<p><a name="page21"></a>Administratora. Są one usuwane lub anonimizowane w okresie do 30 dni od chwili zakończenia świadczenia usług (np. usunięcie zarejestrowanego konta użytkownika, wypisanie z listy Newsletter, itp.)</p>

<p>Wyjątek stanowi sytuacja, która wymaga zabezpieczenia prawnie uzasadnionych celów dalszego przetwarzania tych danych przez Administratora. W takiej sytuacji Administrator będzie przechowywał wskazane dane, od czasu żądania ich usunięcia przez Użytkownika, nie dłużej niż przez okres 3 lat w przypadku naruszenia lub podejrzenia naruszenia zapisów regulaminu serwisu przez Użytkownika</p>

<p><strong>Dane anonimowe (bez danych osobowych) gromadzone automatycznie:</strong></p>

<p>Anonimowe dane statystyczne, niestanowiące danych osobowych, są przechowywane przez Administratora w celu prowadzenia statystyk serwisu przez czas nieoznaczony</p>

<p><strong>§13 Prawa Użytkowników związane z przetwarzaniem danych osobowych</strong></p>

<p>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</p>

<p>• <strong>Prawo dostępu do danych osobowych</strong></p>

<p>Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych osobowych, realizowane na żądanie złożone do Administratora</p>

<p>• <strong>Prawo do sprostowania danych osobowych</strong></p>

<p>Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego sprostowania danych osobowych, które są nieprawidłowe lub / oraz uzupełnienia niekompletnych danych osobowych, realizowane na żądanie złożone do Administratora</p>

<br>
<p><a name="page22"></a>• <strong>Prawo do usunięcia danych osobowych</strong></p>

<p>Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego usunięcia danych osobowych, realizowane na żądanie złożone do Administratora W przypadku kont użytkowników, usunięcie danych polega na anonimizacji danych umożliwiających identyfikację Użytkownika. Administrator zastrzega sobie prawo wstrzymania realizacji żądania usunięcia danych w celu ochrony prawnie uzasadnionego interesu Administratora (np. w gdy Użytkownik dopuścił się naruszenia Regulaminu czy dane zostały pozyskane wskutek prowadzonej korespondencji).</p>

<p>W przypadku usługi Newsletter, Użytkownik ma możliwość samodzielnego usunięcia swoich danych osobowych korzystając z odnośnika umieszczonego w każdej przesyłanej wiadomości e-mail.</p>

<p>• <strong>Prawo do ograniczenia przetwarzania danych osobowych </strong>Użytkownikom przysługuje prawo ograniczenia przetwarzania danych osobowych w przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania prawidłowość danych osobowych, realizowane na żądanie złożone do Administratora</p>

<p>• <strong>Prawo do przenoszenia danych osobowych</strong></p>

<p>Użytkownikom przysługuje prawo uzyskania od Administratora, danych osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego, realizowane na żądanie złożone do Administratora</p>

<p>• <strong>Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych</strong></p>

<p>Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec przetwarzania jego danych osobowych w przypadkach określonych w art. 21 RODO, realizowane na żądanie złożone do Administratora</p>

<p>• <strong>Prawo wniesienia skargi</strong></p>

<p>Użytkownikom przysługuje prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych.</p>

<br>
<p><strong>§14 Kontakt do Administratora</strong></p>

<strong><br>
</strong><p><a name="page23"></a>Z Administratorem można skontaktować się w jeden z poniższych sposobów</p>

<p>• <strong>Adres pocztowy</strong> - Trening Fitness Paula Piotrzkowska Sp.z.o.o, ul. Browarowa 5, 83-200 Starogard Gdański</p>

<p>• <strong>Adres poczty elektronicznej</strong> - paula@treningfitness.com</p>

<p>• <strong>Formularz kontaktowy</strong> - dostępny pod adresem: /kontakt</p>

<p><strong>§15 Wymagania Serwisu</strong></p>

<p>• Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować nieprawidłowe działanie niektórych funkcji Serwisu.</p>

<p>• Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.</p>

<p><strong>§16 Linki zewnętrzne</strong></p>

<p>W Serwisie - artykułach, postach, wpisach czy komentarzach</p>

<p>Użytkowników mogą znajdować się odnośniki do witryn zewnętrznych,</p>

<p>z którymi Właściciel serwisu nie współpracuje. Linki te oraz strony lub</p>

<p>pliki pod nimi wskazane mogą być niebezpieczne dla Twojego</p>

<p>Urządzenia lub stanowić zagrożenie bezpieczeństwa Twoich danych.</p>

<p>Administrator nie ponosi odpowiedzialności za zawartość znajdującą</p>

<p>się poza Serwisem.</p>

<p><strong>§17 Zmiany w Polityce Prywatności</strong></p>

<strong><br>
</strong> <p>• Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Prywatności bez konieczności informowania o tym Użytkowników w zakresie stosowania i wykorzystywania</p>

<br>
<p><a name="page24"></a>danych anonimowych lub stosowania plików Cookie.</p>

<p>• Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Prywatności w zakresie przetwarzania Danych Osobowych, o czym poinformuje Użytkowników posiadających konta użytkownika lub zapisanych do usługi newsletter, za pośrednictwem poczty elektronicznej w terminie do 7 dni od zmiany zapisów. Dalsze korzystanie z usług oznacza zapoznanie się i akceptację wprowadzonych zmian Polityki Prywatności. W przypadku w którym Użytkownik nie będzie się zgadzał z wprowadzonymi zmianami, ma obowiązek usunąć swoje konto z Serwisu lub wypisać się z usługi Newsletter.</p>

<p>• Wprowadzone zmiany w Polityce Prywatności będą publikowane na tej podstronie Serwisu.</p>

<p>• Wprowadzone zmiany wchodzą w życie z chwilą ich publikacji.</p>


</div>
</template>

<script>
export default {
  name: "tos-text",
};
</script>

<style scoped>
</style>