const axios = require("axios");

const instance = axios.create({
  withCredentials: true,
});

module.exports.install = function (Vue) {
  Object.defineProperty(Vue.prototype, "$axios", { value: instance });
};

module.exports.axios = instance;
