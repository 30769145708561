<template>
  <div>
    <v-container :style="'max-width: 1000px'" id="setup-top">
      <LogoHeader v-bind:home="false" v-if="$route.path == '/setup'" ></LogoHeader>
      <UserSetupStepper></UserSetupStepper>
    </v-container>
    <!-- FOOTER -->
    <v-container class="">
      <Footer v-if="$route.path == '/setup'"></Footer>
    </v-container>
  </div>
</template>

<script>
import UserSetupStepper from "@/components/util/UserSetupStepper.vue";
import LogoHeader from "@/components/util/LogoHeader.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "SetupProfile",
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  components: {
    LogoHeader,
    Footer,
    UserSetupStepper
  },
  computed: {
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400&family=Montserrat:wght@500&display=swap");
.montserrat500 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
</style>