// eslint-disable-next-line no-unused-vars
import Vue from "vue";
import App from "./App.vue";
import "./service-worker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import axios from "./plugins/axios";
import "./service-worker";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import underscore from 'vue-underscore';

import captcha from "./plugins/captcha";
import stripe from "./plugins/stripe";

import getDate from "./plugins/sysdate";

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';


Vue.config.productionTip = false;
Vue.config.devtools = false


import Default from "./layouts/Default.vue";
import Blank from "./layouts/Blank.vue";



Vue.component("default-layout", Default);
Vue.component("blank-layout", Blank);

// plugin setup
Vue.use(DashboardPlugin);

//axios
Vue.use(axios);

//captcha
Vue.use(captcha);

//stripe
Vue.use(stripe);

//getDate
Vue.use(getDate);

//notifications toasts
Vue.use(VueToast);

//carousel slide
import VueSlickCarousel from 'vue-slick-carousel'
//import VueCarousel from 'vue-carousel';
Vue.component('slick-carousel',VueSlickCarousel);



//AVATAR CROPPER
import AvatarCropper from 'vue-avatar-cropper'
Vue.component('AvatarCropper', AvatarCropper);

//MOMENT
import moment from 'moment'
Vue.prototype.moment = moment;


//UNDERSCORE
Vue.use(underscore);

//DONUT CHART
Vue.use(Donut);




new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() { },
}).$mount("#application");
