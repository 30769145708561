<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <v-row class="py-1">
      <v-col cols="6" sm="6" class="d-flex justify-start px-0">
        <div
          class="drawer-toggler pa-5 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-600
            text-capitalize
            drawer-toggler
            py-3
            px-0
            rounded-sm
          "
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-else
          color="transparent"
          @click="drawerOpen"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>
      </v-col>

      <v-col cols="6" sm="6" class="d-flex align-center justify-end pr-1">
        <v-btn
                :ripple="false"
                elevation="1"
                color="#fff"
                class="font-weight-bolder font-size-hero-medium py-4 px-7 ms-2"
                :class="'btn-primary button-primary-gradient'"
                @click="openMe()"
                :style="'text-transform: none;'"
                rounded
              >
                <v-icon
                  size="18px"
                  :style="'transform: translateY(1px);'"
                  class="mr-3"
                  >fas fa-child</v-icon
                >
                Ja
              </v-btn>
        <v-menu :rounded="'b-xl'" :offset-y="true" :offset-x="true" :bottom="true">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              class="font-weight-600 text-body ls-0 text-capitalize m-2"
              color="transparent"
            >
              <v-avatar color="primary" size="35">
                <v-img :src="user.avatarUrl"></v-img>
              </v-avatar>
              <span
                class="d-sm-inline font-weight-bold d-none ml-2"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
                >{{user.firstName}}</span
              >
            </v-btn>
          </template>

          <v-list class="pt-0 pb-0">
            <v-list-item link :to="'/profile'">
              <v-list-item-icon class="mr-3">
                <v-icon> fas fa-user </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold">Moje konto</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/admin'" v-if="user.isAdmin">
              <v-list-item-icon class="mr-3">
                <v-icon> fas fa-shield-alt </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold">Admin Panel</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link :to="'/reportsadmin'" v-if="user.isAdmin">
              <v-list-item-icon class="mr-3">
                <v-icon> fas fa-file-excel </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold">Raporty</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="logout">
              <v-list-item-icon  class="mr-3">
                <v-icon> fas fa-sign-out-alt </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold">Wyloguj</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-btn
          depressed
          class="font-weight-600 text-body ls-0 text-capitalize m-2"
          color="transparent"
          @click="logout"
        >
          <v-icon size="16">fas fa-sign-out-alt text-sm</v-icon>
          <span
            class="d-sm-inline font-weight-bold d-none"
            :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
            :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
            >Wyloguj</span
          >
        </v-btn> -->
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      username:""
    };
  },
  methods: {
    openMe(){
      this.$store.commit("setMeDialog",true);
    },   
    drawerOpen() {
      this.togglerActive = !this.togglerActive;
      this.$emit(
        "drawer-toggle",
        Math.floor(Math.random() * (9999 - 2 + 1) + 2)
      );
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    async logout() {
      await this.$axios.get("/api/auth/logout");
      this.$store.commit("logout");
      this.$router.push("/");
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
    
  },
};
</script>
