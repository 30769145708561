<template>
  <div>
    <v-tooltip bottom v-if="tooltip">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-icon :style="`color: ${color};`">
            {{ stars > 0 ? "fas fa-star" : "far fa-star" }}
          </v-icon>
          <v-icon :style="`color: ${color};`">
            {{ stars > 1 ? "fas fa-star" : "far fa-star" }}
          </v-icon>
          <v-icon :style="`color: ${color};`">
            {{ stars > 2 ? "fas fa-star" : "far fa-star" }}
          </v-icon>
          <v-icon :style="`color: ${color};`">
            {{ stars > 3 ? "fas fa-star" : "far fa-star" }}
          </v-icon>
        </div>
      </template>
      <span>{{ label }}</span>
    </v-tooltip>
    <div v-else>
      <v-icon :style="`color: ${color};`">
        {{ stars > 0 ? "fas fa-star" : "far fa-star" }}
      </v-icon>
      <v-icon :style="`color: ${color};`">
        {{ stars > 1 ? "fas fa-star" : "far fa-star" }}
      </v-icon>
      <v-icon :style="`color: ${color};`">
        {{ stars > 2 ? "fas fa-star" : "far fa-star" }}
      </v-icon>
      <v-icon :style="`color: ${color};`">
        {{ stars > 3 ? "fas fa-star" : "far fa-star" }}
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "DifficultyStars",
  data() {
    return {
      stars: 1,
      label: "",
    };
  },
  props: {
    rating: {
      default: "1star",
      type: String,
    },
    tooltip: {
      default: false,
      type: Boolean,
    },
    color: {
      default: "#d62a9d",
      type: String,
    },
  },
  watch: {
    rating: function () {
      try {
      this.stars = parseInt(this.rating.replace("star", ""));
    } catch (error) {
      this.stars = 1;
    }
    switch (this.stars) {
      case 1:
        this.label = "bardzo początkujący";
        break;
      case 2:
        this.label = "początkujący";
        break;
      case 3:
        this.label = "średniozaawansowany";
        break;
      case 4:
        this.label = "zaawansowany";
        break;
      default:
        break;
    }
    },
  },
  mounted() {
    try {
      this.stars = parseInt(this.rating.replace("star", ""));
    } catch (error) {
      this.stars = 1;
    }
    switch (this.stars) {
      case 1:
        this.label = "bardzo początkujący";
        break;
      case 2:
        this.label = "początkujący";
        break;
      case 3:
        this.label = "średniozaawansowany";
        break;
      case 4:
        this.label = "zaawansowany";
        break;
      default:
        break;
    }
  },
};
</script>

<style scoped>
.star-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
</style>