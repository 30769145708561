<template>
  <v-row class="justify-center d-flex mt-6">
    <v-col class="justify-center d-flex">
      <v-img
        class="mx-2 cursor-pointer"
        src="@/assets/img/logo_wide.png"
        max-width="200"
        contain
        @click="home && $router.push('/')"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LogoHeader",
  props: {
    home: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style>
</style>