<template>
  <div>
    <v-container :style="'max-width: 1000px'">
      <LogoHeader id="setup-top" v-bind:home="false"></LogoHeader>
    </v-container>
    <v-container class="">
      <v-row>
        <v-col cols="12">
          <PaymentTeaser></PaymentTeaser>
        </v-col>
      </v-row>
    </v-container>
    <!-- FOOTER -->
    <v-container class="">
      <v-row>
        <v-col cols="12">
          <Footer></Footer>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LogoHeader from "@/components/util/LogoHeader.vue";
import PaymentTeaser from "@/components/util/PaymentTeaser.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "PaymentTeaserView",
  components: {
    LogoHeader,
    Footer,
    PaymentTeaser,
  },
  
};
</script>

<style>
</style>