var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none",class:_vm.navbarFixed
      ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
      : '',attrs:{"color":_vm.background,"height":"auto","flat":""}},[_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"d-flex justify-start px-0",attrs:{"cols":"6","sm":"6"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"drawer-toggler pa-5 cursor-pointer",class:{ active: _vm.togglerActive },on:{"click":_vm.minifyDrawer}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"})])]):_c('v-btn',{staticClass:"\n          font-weight-600\n          text-capitalize\n          drawer-toggler\n          py-3\n          px-0\n          rounded-sm\n        ",class:{
          'btn-dark-toggler-hover': !_vm.hasBg,
          'btn-toggler-hover': _vm.hasBg,
          active: _vm.togglerActive,
        },attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerOpen}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line text-body"}),_c('i',{staticClass:"drawer-toggler-line text-body"}),_c('i',{staticClass:"drawer-toggler-line text-body"})])])],1),_c('v-col',{staticClass:"d-flex align-center justify-end pr-1",attrs:{"cols":"6","sm":"6"}},[_c('v-btn',{staticClass:"font-weight-bolder font-size-hero-medium py-4 px-7 ms-2",class:'btn-primary button-primary-gradient',style:('text-transform: none;'),attrs:{"ripple":false,"elevation":"1","color":"#fff","rounded":""},on:{"click":function($event){return _vm.openMe()}}},[_c('v-icon',{staticClass:"mr-3",style:('transform: translateY(1px);'),attrs:{"size":"18px"}},[_vm._v("fas fa-child")]),_vm._v(" Ja ")],1),_c('v-menu',{attrs:{"rounded":'b-xl',"offset-y":true,"offset-x":true,"bottom":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-600 text-body ls-0 text-capitalize m-2",attrs:{"depressed":"","color":"transparent"}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"color":"primary","size":"35"}},[_c('v-img',{attrs:{"src":_vm.user.avatarUrl}})],1),_c('span',{staticClass:"d-sm-inline font-weight-bold d-none ml-2",class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },style:(!_vm.hasBg ? 'color: rgba(0,0,0, .6)' : '')},[_vm._v(_vm._s(_vm.user.firstName))])],1)]}}])},[_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-list-item',{attrs:{"link":"","to":'/profile'}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" fas fa-user ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Moje konto")])],1),(_vm.user.isAdmin)?_c('v-list-item',{attrs:{"link":"","to":'/admin'}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" fas fa-shield-alt ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Admin Panel")])],1):_vm._e(),_c('v-divider'),(_vm.user.isAdmin)?_c('v-list-item',{attrs:{"link":"","to":'/reportsadmin'}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" fas fa-file-excel ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Raporty")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" fas fa-sign-out-alt ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v("Wyloguj")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }