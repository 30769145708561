/* eslint-disable no-console */

import { register } from "register-service-worker";

console.log('service worker loaded');
register(`/worker.js`, {
  ready() {
    console.log(
      "App served from cache."
    );
  },
  registered() {
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    console.log('New content is available; Refreshing cache.');
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
